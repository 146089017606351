/* global config */
/* eslint no-undef: "error" */
// Tell the linter to ignore config undefined, the file config.js is imported from index.html.
// It cannot be bundled at build time with all files in /src,
// we override the file contents from docker environment variables

// This module contains accessors that can be overridden by the docker container
// The local development environment and the unit test environment will use the local .env files
import { config as remoteConfig } from '@/config.js';

const getConfigValue = (envVarName, defaultValue = null) => {
    return import.meta.env[`VITE_${envVarName}`] &&
        import.meta.env[`VITE_${envVarName}`].trim()
        ? import.meta.env[`VITE_${envVarName}`]
        : remoteConfig[envVarName];
};

export const appConfig = {
    version: import.meta.env.PACKAGE_VERSION,
    apiUrl: getConfigValue('API_URL'),
    env: getConfigValue('ENV'),
    devTools: getConfigValue('DEV_TOOL'),
    auth: {
        domain: getConfigValue('AUTH_DOMAIN'),
        clientId: getConfigValue('AUTH_CLIENT_ID'),
        audience: getConfigValue('AUTH_AUDIENCE'),
    },
    logging: {
        level: getConfigValue('LOGGING_LEVEL'),
        handlers: getConfigValue('LOGGING_HANDLERS')?.split(' '),
        grafanaUrl: getConfigValue('LOGGING_GRAFANA_URL'),
        grafanaAppName: getConfigValue('LOGGING_GRAFANA_APP_NAME'),
        rumEnabled: getConfigValue('LOGGING_RUM_ENABLED') === 'true',
        tracingEnabled: getConfigValue('LOGGING_TRACING_ENABLED') === 'true',
    },
    external: {
        patentLabUiUrl: getConfigValue('PATENT_LAB_UI_URL'),
        entityPortalUiUrl: getConfigValue('ENTITY_PORTAL_UI_URL'),
        tengUiUrl: getConfigValue('TEMPLATING_ENGINE_UI_URL'),
        entitySvcUrl: getConfigValue('ENTITY_API_URL'),
        clusterSvcUrl: getConfigValue('CLUSTER_SVC_URL'),
        patentResolverUrl: getConfigValue('PATENT_RESOLVER_URL'),
        litSearchUrl: getConfigValue('LITIGATION_SEARCH_URL'),
    },
    unleash: {
        url: getConfigValue('UNLEASH_URL'),
        clientKey: getConfigValue('UNLEASH_CLIENT_KEY'),
        refreshInterval: getConfigValue('UNLEASH_REFRESH_INTERVAL'),
        appName: getConfigValue('UNLEASH_APP_NAME'),
        env: getConfigValue('UNLEASH_ENVIRONMENT'),
    },
    incubator: {
        enabled: getConfigValue('INCUBATOR_ENABLED') === 'true',
    },
};
