import { authGuard } from '@auth0/auth0-vue';
import LoginCallback from '@/views/auth/LoginCallback.vue';
import LogOut from '@/views/auth/LogOut.vue';

function lazyLoad(view) {
    return () => import(`../views/${view}.vue`);
}
function lazyLoadIncubator(view) {
    return () => import(`../views/incubator/${view}.vue`);
}

export const loginPath = '/login';
export const routes = [
    {
        path: '',
        redirect: '/dashboard',
    },
    {
        path: '/loginCallback',
        name: 'Login Callback',
        component: LoginCallback,
    },
    {
        path: '/logOut',
        name: 'Log Out',
        component: LogOut,
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        beforeEnter: authGuard,
        components: {
            outside: lazyLoad('DashboardPage'),
        },
    },
    {
        path: '/projects',
        name: 'Projects',
        beforeEnter: authGuard,
        component: lazyLoad('ProjectsPage'),
    },
    {
        path: '/projects/:projectID',
        name: 'Project',
        beforeEnter: authGuard,
        component: lazyLoad('QueryPage'),
    },
    {
        path: '/projects/:projectID/queries/:queryID/:tab?',
        name: 'Query',
        beforeEnter: authGuard,
        component: lazyLoad('AssigneePage'),
    },
    {
        path: '/similarity/:similarityID',
        name: 'Similarity',
        beforeEnter: authGuard,
        component: lazyLoad('SimilarityPage'),
    },
    {
        path: '/patent-similarity/:similarityID',
        name: 'Patent Similarity',
        beforeEnter: authGuard,
        component: lazyLoad('PatentSimilarityPage'),
    },
    {
        path: '/cluster/:clusterID',
        name: 'Cluster',
        beforeEnter: authGuard,
        component: lazyLoad('ClusterPage'),
    },
    {
        path: '/cluster-board-upload',
        name: 'Cluster Board Upload',
        beforeEnter: authGuard,
        components: {
            outside: lazyLoad('ClusterBoardPage'),
        },
    },
    {
        path:
            '/cluster-board/:clusterID/jobID/:jobID/vectorType/:vectorType/reportPK/:reportPK',
        name: 'Cluster Board Path',
        beforeEnter: authGuard,
        components: {
            outside: lazyLoad('ClusterBoardPage'),
        },
    },
    {
        path:
            '/cluster-board/:clusterID/jobID/:jobID/vectorType/:vectorType/reportPK/:reportPK/bookmarkPK/:bookmarkPK?',
        name: 'Cluster Board Path',
        beforeEnter: authGuard,
        components: {
            outside: lazyLoad('ClusterBoardPage'),
        },
    },
    {
        path: '/cluster-board/:clusterID/vectorPK/:vectorPK/reportPK/:reportPK',
        name: 'Cluster Board Legacy',
        beforeEnter: authGuard,
        components: {
            outside: lazyLoad('LegacyClusterBoardPage'),
        },
    },
    {
        path: '/incubator/gallery',
        name: 'Incubator Gallery',
        beforeEnter: authGuard,
        components: {
            outside: lazyLoadIncubator('Gallery'),
        },
    },
    {
        path: '/incubator/patent-resolver',
        name: 'Patent Resolver',
        beforeEnter: authGuard,
        components: {
            outside: lazyLoadIncubator('PatentResolverPage'),
        },
    },
    {
        path: '/incubator/international-litigation',
        name: 'International Litigation',
        beforeEnter: authGuard,
        components: {
            outside: lazyLoadIncubator('LitigationSearchPage'),
        },
    },
    {
        // Adding for now since urls were distributed to users (can eventually remove) 1/8/2020 - Jared
        path: '/britzer-board',
        redirect: '/cluster-board-upload',
        beforeEnter: authGuard,
    },
    {
        path: '/search/:setPk?/:childSetPk?',
        name: 'Search',
        beforeEnter: authGuard,
        components: {
            outside: lazyLoad('SearchPage'),
        },
    },
    {
        path: '/dataset-library',
        name: 'Dataset Library',
        beforeEnter: authGuard,
        components: {
            outside: lazyLoad('DatasetLibrary'),
        },
    },
    {
        path: '/dataset/:setPk/:childSetPk?',
        name: 'Dataset',
        beforeEnter: authGuard,
        components: {
            outside: lazyLoad('DatasetPage'),
        },
    },
    {
        path: '/next',
        name: 'Next Cool Feature',
        beforeEnter: authGuard,
        components: {
            outside: lazyLoad('NextCoolFeature'),
        },
    },
    {
        path: '/entity/:entityPk/:tabNum?/:setPk?',
        name: 'Entity',
        beforeEnter: authGuard,
        components: {
            outside: lazyLoad('EntityPage'),
        },
    },
    {
        path: '/models',
        name: 'Models',
        beforeEnter: authGuard,
        components: { outside: lazyLoad('ModelsPage') },
    },
    {
        path: '/model/:modelPk',
        name: 'Model',
        beforeEnter: authGuard,
        components: {
            outside: lazyLoad('ModelPage'),
        },
    },
    {
        path: '/model/model-patents/:modelPk/:childSetPk?',
        name: 'Model Patents',
        beforeEnter: authGuard,
        components: {
            outside: lazyLoad('ModelPatentsPage'),
        },
    },
    {
        path: '/model/run/:jobPk',
        name: 'Model Run',
        beforeEnter: authGuard,
        components: {
            outside: lazyLoad('ModelRunPage'),
        },
    },
    {
        name: 'Not Found',
        path: '/:catchAll(.*)',
        redirect: '',
    },
];
