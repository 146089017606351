import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faArrowToBottom,
    faChartNetwork,
    faCopy,
    faCubes,
    faEdit,
    faFileDownload,
    faThumbtack,
    faTrash,
    faTriangleExclamation,
    faCirclePlus as falCirclePlus,
    faCircleMinus as falCircleMinus,
    faBuilding as falBuilding,
    faSwap,
    faPenSwirl,
    faPersonRunning,
    faSparkles,
    faArrowDownWideShort,
} from '@fortawesome/pro-light-svg-icons';

import {
    faFilter,
    faEnvelope,
    faDiagramVenn,
    faEllipsis,
    faQuestion,
    faCalendar,
} from '@fortawesome/pro-regular-svg-icons';

import {
    faEnvelope as fasEnvelope,
    faArrowLeftLong,
    faUsers,
    faEyeSlash,
    faBuilding,
    faCircleArrowRight,
    faFileAlt,
    faHome,
    faThumbtack as fasThumbtack,
    faUpload,
    faMagnifyingGlass,
    faSlidersV,
    faFilter as fasFilter,
    faCirclePlus,
    faCircleMinus,
    faDiagramCells,
    faWandSparkles,
    faGamepadModern,
    faSquareBolt,
    faFlag,
    faLocationDot,
    faSackDollar,
    faCopy as fasCopy,
    faBan,
    faCircleCheck,
    faBars,
    faMinus,
    faExpand,
    faCircle,
    faArrowUpRightFromSquare,
    faChevronRight,
    faChevronLeft,
    faTrashCan,
} from '@fortawesome/pro-solid-svg-icons';

import { faBooks } from '@fortawesome/sharp-solid-svg-icons';

import { faRightLeft } from '@fortawesome/pro-duotone-svg-icons';

library.add(
    faArrowLeftLong,
    faArrowToBottom,
    faBuilding,
    faChartNetwork,
    faCircleArrowRight,
    faCopy,
    faCubes,
    faEdit,
    faEllipsis,
    faEnvelope,
    fasEnvelope,
    faEyeSlash,
    faFileAlt,
    faFileDownload,
    faHome,
    faQuestion,
    fasThumbtack,
    faThumbtack,
    faTriangleExclamation,
    faTrash,
    faArrowToBottom,
    faTriangleExclamation,
    faUpload,
    faUsers,
    faDiagramVenn,
    faUpload,
    faBooks,
    faMagnifyingGlass,
    faFilter,
    faSlidersV,
    fasFilter,
    faCirclePlus,
    falCirclePlus,
    faCircleMinus,
    falCircleMinus,
    faCalendar,
    faDiagramCells,
    faWandSparkles,
    faGamepadModern,
    falBuilding,
    faSquareBolt,
    faFlag,
    faLocationDot,
    faSackDollar,
    fasCopy,
    faCircleCheck,
    faBan,
    faBars,
    faMinus,
    faExpand,
    faCircle,
    faArrowUpRightFromSquare,
    faChevronRight,
    faChevronLeft,
    faSwap,
    faPenSwirl,
    faTrashCan,
    faPersonRunning,
    faSparkles,
    faArrowDownWideShort,
    faRightLeft
);

export default FontAwesomeIcon;
