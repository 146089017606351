<!-- NOT UPDATING THIS TO COMPOSITION - DEPRECIATING SOON -->

<template>
    <div class="cluster-simple-form">
        <h6>Simple Clustering</h6>
        <p class="mt-2">
            {{
                !clusterJobRunning
                    ? 'Try these defaults first; you\’ll typically be able to choose from multiple results. Then try adjusting one value at a time if you need to tune the output.'
                    : ''
            }}
        </p>
        <!-- Form -->
        <form
            v-if="!clusterJobRunning && !errorMsg"
            class="cluster-run-config mt-2"
            @submit.prevent="runClustering"
        >
            <AonContainer>
                <AonRow>
                    <AonCol :class="['mb-5', 'aon-col-12']">
                        <AonCheckbox
                            class="only-differentiated-toggle"
                            label="Only Return Most Differentiated Results"
                            :is-checked="onlyDifferentiated"
                            @clicked="onlyDifferentiatedToggled"
                        />
                        <AonCheckbox
                            v-tooltip="{
                                content:
                                    'Check to set a max limit. Uncheck for no limit.',
                                delay: { show: 500, hide: 100 },
                            }"
                            class="mt-5"
                            label="Enable Cluster Max Size"
                            :is-checked="enableMaxSize"
                            @clicked="enableMaxSizeToggled"
                        />
                        <AonCheckbox
                            v-tooltip="{
                                content: 'Check to run clustering for markets',
                                delay: { show: 500, hide: 100 },
                            }"
                            class="mt-5"
                            label="Enable Market Clustering"
                            :is-checked="enableMarketClustering"
                            @clicked="enableMarketClusteringToggled"
                        />
                    </AonCol>
                </AonRow>

                <!-- Count -->
                <AonRow>
                    <AonCol :class="['mb-5', 'aon-col-6']">
                        <AonInput
                            ref="minClusterCount"
                            :model-value="minClusterCount"
                            label="Cluster Count Min"
                            type="number"
                            information-icon="Set min limit for the number of clusters. If any result has a cluster count outside of these limits, the whole report will be dropped"
                            :error="minClusterCountError"
                            :error-options="minClusterCountErrorOptions"
                            @update:modelValue="
                                setClusteringConfigValue(
                                    'min_num_clusters',
                                    $event
                                )
                            "
                        />
                    </AonCol>
                    <AonCol :class="['mb-5', 'aon-col-6']">
                        <AonInput
                            ref="maxClusterCount"
                            :model-value="maxClusterCount"
                            label="Cluster Count Max"
                            type="number"
                            information-icon="Set max limit for the number of clusters. If any result has a cluster count outside of these limits, the whole report will be dropped"
                            :error="maxClusterCountError"
                            :error-options="maxClusterCountErrorOptions"
                            @update:modelValue="
                                setClusteringConfigValue(
                                    'max_num_clusters',
                                    $event
                                )
                            "
                        />
                    </AonCol>
                </AonRow>

                <!-- Size -->
                <AonRow>
                    <AonCol :class="['mb-5', 'aon-col-6']">
                        <AonInput
                            ref="minClusterSize"
                            :model-value="minClusterSize"
                            label="Cluster Size Min"
                            type="number"
                            information-icon="Set min limit for the size of each clusters. If any clusters in a result have patent counts outside of these limits, the whole report will be dropped"
                            :error="minClusterSizeError"
                            :error-options="minClusterSizeErrorOptions"
                            @update:modelValue="
                                setClusteringConfigValue(
                                    'min_cluster_size',
                                    $event
                                )
                            "
                        />
                    </AonCol>
                    <AonCol :class="['mb-5', 'aon-col-6']">
                        <AonInput
                            :key="maxClusterSizeKey"
                            ref="maxClusterSize"
                            :model-value="maxClusterSize"
                            label="Cluster Size Max"
                            type="number"
                            information-icon="Set max limit for the size of each clusters. If any clusters in a result have patent counts outside of these limits, the whole report will be dropped"
                            :error="maxClusterSizeError"
                            :error-options="maxClusterSizeErrorOptions"
                            :disabled="!enableMaxSize"
                            @update:modelValue="
                                setClusteringConfigValue(
                                    'max_cluster_size',
                                    $event
                                )
                            "
                        />
                    </AonCol>
                </AonRow>

                <AonRow>
                    <AonCol :class="['aon-col-12']">
                        <AonButton
                            ref="runClustering"
                            class="mr-5"
                            label="Run Clustering"
                            :is-form-submit="true"
                        />
                        <AonButton
                            label="Cancel"
                            type="ghost"
                            @clicked="cancelRun"
                        />
                    </AonCol>
                </AonRow>
            </AonContainer>
        </form>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import AonButton from '@moatmetrics/armory/src/components/base/AonButton.vue';
import AonCheckbox from '@moatmetrics/armory/src/components/base/forms/AonCheckbox.vue';
import AonInput from '@moatmetrics/armory/src/components/base/forms/AonInput.vue';

export default {
    name: 'ClusterSimpleForm',
    components: {
        AonCheckbox,
        AonInput,
        AonButton,
    },
    data() {
        return {
            enableMaxSize: false,
            minClusterSizeLimit: 2,
            minClusterCountLimit: 2,
            apiConfigIsLoaded: false,
            chosenVectorType: 'technology',
            onlyDifferentiated: true,
            enableMarketClustering: false,

            // Input Validation
            validateMinMinClusterCount: 1,
            validateMaxMinClusterCount: 500,
            validateMinMaxClusterCount: 1,
            validateMaxMaxClusterCount: 500,

            validateMinMinClusterSize: 1,
            validateMaxMinClusterSize: 100000,
            validateMinMaxClusterSize: 1,
            validateMaxMaxClusterSize: 100000,

            minClusterCountError: false,
            minClusterCountErrorOptions: null,
            maxClusterCountError: false,
            maxClusterCountErrorOptions: null,

            minClusterSizeError: false,
            minClusterSizeErrorOptions: null,
            maxClusterSizeError: false,
            maxClusterSizeErrorOptions: null,
            maxClusterSizeKey: 0,
        };
    },
    computed: {
        ...mapState('cluster', [
            'errorMsg',
            'clusterJobRunning',
            'clusterRunParams',
            'clusterRunPartialSuccess',
            'clusterRunPk',
            'clusteringConfigs',
            'defaultClusteringConfig',
            'defaultVectorType',
            'vectorTypes',
        ]),
        ...mapState('apiConfig', { apiConfig: 'config' }),
        maxClusterSizeMin() {
            return this.minClusterSize + 1;
        },
        maxClusterCountMin() {
            return this.minClusterCount + 1;
        },
        minNumberInputsMaxValue() {
            // max minus 1 so max number inputs can be one higher
            return this.maxNumberInputsMaxValue - 1;
        },
        maxNumberInputsMaxValue() {
            return Number.MAX_SAFE_INTEGER;
        },
        vectorTypeDropdownLabel() {
            return (
                this.defaultVectorType[0].toUpperCase() +
                this.defaultVectorType.slice(1)
            );
        },
        currentClusteringConfig() {
            return this.clusteringConfigs[this.defaultVectorType];
        },
        minClusterSize() {
            return this.clusteringConfigs[this.defaultVectorType]
                .min_cluster_size;
        },
        minClusterCount() {
            return this.clusteringConfigs[this.defaultVectorType]
                .min_num_clusters;
        },
        maxClusterSize() {
            return this.clusteringConfigs[this.defaultVectorType]
                .max_cluster_size;
        },
        maxClusterCount() {
            return this.clusteringConfigs[this.defaultVectorType]
                .max_num_clusters;
        },
        dropdownVectorTypes() {
            return this.vectorTypes.map(t => {
                return {
                    itemText: t[0].toUpperCase() + t.slice(1),
                    itemValue: t,
                };
            });
        },
        partialSuccessErrorMsg() {
            if (!this.clusterRunPartialSuccess) return '';

            let wholeMessage = '';
            for (let i = 0; i < this.clusterRunPartialSuccess.length; i++) {
                const element = this.clusterRunPartialSuccess[i];
                if (element.job_part.status === 'FAILURE') {
                    // Add spacing between errors
                    if (wholeMessage.length > 0) {
                        wholeMessage += '\n\n';
                    }

                    wholeMessage += `${element.name.charAt(0).toUpperCase() +
                        element.name.slice(1)} cluster: `;

                    for (let j = 0; j < element.job_part.messages.length; j++) {
                        const message = element.job_part.messages[j];
                        wholeMessage += message.message;
                    }
                }
            }
            return wholeMessage;
        },
    },
    beforeMount() {
        if (this.$store.state.apiConfig.config) {
            this.minClusterSizeLimit =
                this.$store.state.apiConfig.config
                    .smallest_allowed_cluster_size ?? 2;
            this.minClusterCountLimit =
                this.$store.state.apiConfig.config
                    .smallest_allowed_cluster_count ?? 2;
            this.$store.commit(
                'cluster/SET_VECTOR_TYPES',
                this.$store.state.apiConfig.config.clustering_vector_types
            );
            this.$store.commit(
                'cluster/SET_DEFAULT_VECTOR_TYPE',
                this.$store.state.cluster.vectorTypes[0]
            );
            for (let index in this.$store.state.cluster.vectorTypes) {
                let vectorType = this.$store.state.cluster.vectorTypes[index];
                this.$store.commit('cluster/SET_CLUSTERING_CONFIG', {
                    vectorType,
                    config: this.$store.state.cluster.defaultClusteringConfig,
                });
            }
            for (let vectorType in this.$store.state.cluster
                .clusteringConfigs) {
                this.setClusteringConfigValue(
                    'min_cluster_size',
                    this.minClusterSize,
                    vectorType
                );
                this.setClusteringConfigValue(
                    'min_num_clusters',
                    this.minClusterCountLimit,
                    vectorType
                );
                this.setClusteringConfigValue(
                    'max_num_clusters',
                    this.$store.state.apiConfig.config
                        .default_max_cluster_count ?? 250,
                    vectorType
                );
                this.setClusteringConfigValue(
                    'drop_lower_scored_results',
                    this.$store.state.apiConfig.config
                        .default_drop_lower_scored_results ?? true,
                    vectorType
                );
            }
        }
    },
    methods: {
        ...mapMutations('cluster', [
            'SET_CLUSTER_PROCESS_ACTIVE',
            'SET_CLUSTER_RUN_PARAMS',
            'SET_ERROR_MSG',
            'SET_DEFAULT_VECTOR_TYPE',
            'SET_CLUSTERING_CONFIG_VALUE',
            'SET_CLUSTERING_CONFIG',
            'SET_VECTOR_TYPES',
        ]),
        ...mapActions('cluster', ['submitClusterJob', 'resetClusterRunState']),
        enableMaxSizeToggled() {
            this.enableMaxSize = !this.enableMaxSize;
            this.setClusteringConfigValue(
                'max_cluster_size',
                this.enableMaxSize ? this.minClusterSize + 1 : null
            );
            this.maxClusterSizeKey += 1;
        },
        onlyDifferentiatedToggled() {
            this.onlyDifferentiated = !this.onlyDifferentiated;
            for (let index in this.$store.state.cluster.vectorTypes) {
                let vectorType = this.$store.state.cluster.vectorTypes[index];
                this.setClusteringConfigValue(
                    'drop_lower_scored_results',
                    this.onlyDifferentiated,
                    vectorType
                );
            }
        },
        enableMarketClusteringToggled() {
            this.enableMarketClustering = !this.enableMarketClustering;
        },
        runClustering() {
            const anyErrors = this.errorChecker();

            if (!anyErrors) {
                return;
            }
            let vectors = [];
            for (let vectorType in this.clusteringConfigs) {
                if (vectorType === 'market' && !this.enableMarketClustering) {
                    continue;
                }
                let clusteringConfig = this.clusteringConfigs[vectorType];
                vectors.push({
                    name: vectorType,
                    is_default: vectorType === this.defaultVectorType,
                    configuration: {
                        ...clusteringConfig,
                        max_cluster_size:
                            clusteringConfig.max_cluster_size || -1,
                    },
                });
            }

            let payload = {
                ...this.clusterRunParams,
                vectors: vectors,
            };

            this.submitClusterJob(payload);
        },
        continueToClusterPage() {
            this.$router.push(`/cluster/${this.clusterRunPk}`);
        },
        clearError() {
            // FIXME: This is unused now.... so do we want the "close" btn of the modal to just trigger this clear function, or trigger cancelRun()???
            this.SET_ERROR_MSG(null);
        },
        cancelRun() {
            this.resetClusterRunState();
            this.$store.commit('cluster/SET_CLUSTER_PROCESS_ACTIVE', false);
            this.$store.commit('cluster/SET_CLUSTER_RUN_PARAMS', null);
        },
        setClusteringConfigValue(configKey, val, vectorType) {
            if (typeof val === 'string') {
                val = parseInt(val);
            } else {
                val = val;
            }

            this.SET_CLUSTERING_CONFIG_VALUE({
                vectorType: vectorType || this.defaultVectorType,
                configKey,
                val,
            });
        },
        errorChecker() {
            this.minClusterCountError = false;
            this.minClusterCountErrorOptions = null;
            this.maxClusterCountError = false;
            this.maxClusterCountErrorOptions = null;

            this.minClusterSizeError = false;
            this.minClusterSizeErrorOptions = null;
            this.maxClusterSizeError = false;
            this.maxClusterSizeErrorOptions = null;

            let minClusterCountNum = this.$refs.minClusterCount.$refs
                .inputElement.value
                ? parseInt(this.$refs.minClusterCount.$refs.inputElement.value)
                : 0;
            let maxClusterCountNum = this.$refs.maxClusterCount.$refs
                .inputElement.value
                ? parseInt(this.$refs.maxClusterCount.$refs.inputElement.value)
                : 0;
            let minClusterSizeNum = this.$refs.minClusterSize.$refs.inputElement
                .value
                ? parseInt(this.$refs.minClusterSize.$refs.inputElement.value)
                : 0;

            if (
                minClusterCountNum < this.validateMinMinClusterCount ||
                minClusterCountNum > this.validateMaxMinClusterCount
            ) {
                this.minClusterCountError = true;
                this.minClusterCountErrorOptions = {
                    errorMsg:
                        'Min Cluster Count number must be higher than 1 and lower than 500',
                };
            }

            if (
                maxClusterCountNum < this.validateMinMaxClusterCount ||
                maxClusterCountNum > this.validateMaxMaxClusterCount
            ) {
                this.maxClusterCountError = true;
                this.maxClusterCountErrorOptions = {
                    errorMsg:
                        'Max Cluster Count number must be higher than 1 and lower than 500',
                };
            }

            if (minClusterCountNum >= maxClusterCountNum) {
                this.minClusterCountError = true;
                this.minClusterCountErrorOptions = {
                    errorMsg:
                        'Min Cluster Count number must be lower than the Max Cluster Count',
                };
            }

            if (
                minClusterSizeNum < this.validateMinMinClusterSize ||
                minClusterSizeNum > this.validateMaxMinClusterSize
            ) {
                this.minClusterSizeError = true;
                this.minClusterSizeErrorOptions = {
                    errorMsg:
                        'Min Cluster Size number must be higher than 1 and lower than 100000',
                };
            }

            if (this.enableMaxSize) {
                let maxClusterSizeNum = this.$refs.maxClusterSize.$refs
                    .inputElement.value
                    ? parseInt(
                          this.$refs.maxClusterSize.$refs.inputElement.value
                      )
                    : 0;

                if (
                    maxClusterSizeNum < this.validateMinMaxClusterSize ||
                    maxClusterSizeNum > this.validateMaxMaxClusterSize
                ) {
                    this.maxClusterSizeError = true;
                    this.maxClusterSizeErrorOptions = {
                        errorMsg:
                            'Max Cluster Size number must be higher than 1 and lower than 100000',
                    };
                }

                if (minClusterSizeNum >= maxClusterSizeNum) {
                    this.minClusterSizeError = true;
                    this.minClusterSizeErrorOptions = {
                        errorMsg:
                            'Min Cluster Size number must be lower than the Max Cluster Size number',
                    };
                }

                if (
                    this.minClusterCountError ||
                    this.maxClusterCountError ||
                    this.minClusterSizeError ||
                    this.maxClusterSizeError
                ) {
                    return false;
                }
            }

            if (
                this.minClusterCountError ||
                this.maxClusterCountError ||
                this.minClusterSizeError
            ) {
                return false;
            }

            return true;
        },
    },
};
</script>

<style scoped lang="scss">
.cluster-simple-form {
    width: 100%;
    height: 100%;
}
</style>
<style lang="scss"></style>
