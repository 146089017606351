import axios from 'axios';
import { logger } from '@moatmetrics/vue-logger';
import { appConfig } from '@/common/appConfig';

export async function apiGet(
    path,
    errorHandler = null,
    retryErrorHandler = null,
    params = null
) {
    try {
        // CHANGE W/PINIA
        let finalPath = path.includes('http')
            ? path
            : `${appConfig.apiUrl}/${path}`;
        let response = await axios.get(finalPath, { params });

        if (response.status.toString().match(/[23]\d\d/)) return response;

        logger.error(
            `API returned non 2xx for request. Status: ${response.status}; Request path: ${path}`
        );

        if (
            [429, 499, 502, 503, 504].includes(response.status) &&
            retryErrorHandler !== null
        ) {
            return retryErrorHandler(response);
        }

        return errorHandler !== null ? errorHandler(response) : response;
    } catch (ex) {
        logger.error(ex);
        return errorHandler !== null ? errorHandler(null, ex) : null;
    }
}

export async function apiPost(path, body, errorHandler = null) {
    try {
        let requestUrl = `${appConfig.apiUrl}/${path}`;
        let response = await axios.post(requestUrl, body);

        if (response.status.toString().match(/[23]\d\d/)) return response;

        logger.error(
            `API returned non 2xx for request. Status: ${response.status}; Request url: ${requestUrl}`
        );

        return errorHandler !== null ? errorHandler(response) : response;
    } catch (ex) {
        logger.error(ex);
        return errorHandler !== null ? errorHandler(null, ex) : null;
    }
}
