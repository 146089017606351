<template>
    <div class="cluster-adaptive-form">
        <AonAlertBox
            class="mb-3"
            type="caution"
            title="Please note: Adaptive Clustering is currently in Beta. It may be subject to change or undergo adjustments based on user feedback. "
        />
        <h6 class="mb-4">Adaptive Clustering</h6>
        <form @submit.prevent="runAdaptiveClustering">
            <AonInput
                :model-value="minClusterCountFinal"
                label="Cluster Count Limit"
                type="number"
                information-icon="Set the upper limit for the number of clusters returned"
                :error="minClusterCountError"
                :error-options="minClusterCountErrorOptions"
                @update:modelValue="
                    setClusteringConfigIntValue('min_num_clusters', $event)
                "
            />
            <template v-if="experimentalClusteringParamsFeatureFlag">
                <AonInput
                    class="mt-6"
                    :model-value="experimentalParamsFinal"
                    label="Experimental Parameters"
                    information-icon="Experimental parameters for adaptive clustering"
                    @update:modelValue="
                        setClusteringConfigValue('experimental_params', $event)
                    "
                />
            </template>
            <div class="button-holder mt-6">
                <AonButton
                    ref="adaptiveSubmit"
                    class="mr-5"
                    label="Run Clustering"
                    :is-form-submit="true"
                />
                <AonButton label="Cancel" type="ghost" @clicked="cancelRun" />
            </div>
        </form>
    </div>
</template>

<script>
export default {
    name: 'ClusterAdaptiveForm',
};
</script>

<script setup>
import { computed, onBeforeMount, ref } from 'vue';
import { useStore } from 'vuex';

import AonInput from '@moatmetrics/armory/src/components/base/forms/AonInput.vue';
import AonButton from '@moatmetrics/armory/src/components/base/AonButton.vue';
import AonAlertBox from '@moatmetrics/armory/src/components/base/AonAlertBox.vue';
import { useFlag } from '@unleash/proxy-client-vue';

//flags
const experimentalClusteringParamsFeatureFlag = useFlag(
    'ipscape.experimentalClusteringParamsFeatureFlag'
);

const store = useStore();

//data
const minClusterCountError = ref(false);
const minClusterCountErrorOptions = ref(null);
const validateMinminClusterCount = ref(2);
const validateMaxminClusterCount = ref(500);

//computed
const defaultVectorType = computed(() => store.state.cluster.defaultVectorType);
const defaultAdaptiveClusteringConfig = computed(
    () => store.state.cluster.defaultAdaptiveClusteringConfig
);
const clusteringConfigs = computed(() => store.state.cluster.clusteringConfigs);
const clusterRunParams = computed(() => store.state.cluster.clusterRunParams);
const vectorTypes = computed(() => store.state.cluster.vectorTypes);

const minClusterCount = computed(
    () => clusteringConfigs.value.technology.min_num_clusters
);
const minClusterCountFinal = computed({
    get() {
        return minClusterCount.value;
    },
    set(value) {
        minClusterCount.value = value;
    },
});
const experimentalParams = computed(
    () => clusteringConfigs.value.technology.experimental_params
);
const experimentalParamsFinal = computed({
    get() {
        return experimentalParams.value;
    },
    set(value) {
        experimentalParams.value = value;
    },
});

onBeforeMount(() => {
    for (let index in vectorTypes.value) {
        let vectorType = vectorTypes.value[index];
        store.commit('cluster/SET_CLUSTERING_CONFIG', {
            vectorType,
            config: defaultAdaptiveClusteringConfig.value,
        });
    }
});

//methods
const setClusteringConfigIntValue = (configKey, val, vectorType) => {
    if (typeof val === 'string') {
        val = parseInt(val);
    } else {
        val = val;
    }

    store.commit('cluster/SET_CLUSTERING_CONFIG_VALUE', {
        vectorType: vectorType || defaultVectorType.value,
        configKey,
        val,
    });
};

const setClusteringConfigValue = (configKey, val, vectorType) => {
    store.commit('cluster/SET_CLUSTERING_CONFIG_VALUE', {
        vectorType: vectorType || defaultVectorType.value,
        configKey,
        val,
    });
};

const runAdaptiveClustering = () => {
    const anyErrors = errorChecker();

    if (!anyErrors) {
        return;
    }

    // currently in flux
    let vectors = [];
    if (clusteringConfigs.value) {
        vectors.push({
            name: 'technology',
            is_default: true,
            configuration: {
                ...clusteringConfigs.value.technology,
                max_num_clusters:
                    clusteringConfigs.value.technology.min_num_clusters + 1,
            },
        });
    }

    let payload = {
        ...clusterRunParams.value,
        vectors: vectors,
        cluster_strategy: 'adaptive',
    };

    store.dispatch('cluster/submitClusterJob', payload);
};

const cancelRun = () => {
    store.dispatch('cluster/resetClusterRunState');
    store.commit('cluster/SET_CLUSTER_PROCESS_ACTIVE', false);
    store.commit('cluster/SET_CLUSTER_RUN_PARAMS', null);
};

const errorChecker = () => {
    minClusterCountError.value = false;
    minClusterCountErrorOptions.value = null;

    let minClusterCountNum = minClusterCount.value
        ? parseInt(minClusterCountFinal.value)
        : 0;

    if (
        minClusterCountNum < validateMinminClusterCount.value ||
        minClusterCountNum > validateMaxminClusterCount.value
    ) {
        minClusterCountError.value = true;
        minClusterCountErrorOptions.value = {
            errorMsg:
                'Min Cluster Count number must be higher than 1 and lower than or equal to 249',
        };
        return false;
    }

    return true;
};
</script>

<style scoped lang="scss">
.cluster-adaptive-form {
    width: 100%;
    height: 100%;
}
</style>
