import { createRouter as createVueRouter, createWebHistory } from 'vue-router';
import { store } from '@/store/index.js';

export const createRouter = (app, routes) => {
    const router = createVueRouter({
        history: createWebHistory(import.meta.env.BASE_URL),
        routes,
    });

    router.beforeEach((to, from, next) => {
        if (to.name === 'Cluster Board Upload') {
            store.commit('cluster/SHOW_CLUSTER_BOARD_FILE_UPLOAD', true);
            store.commit('cluster/SET_PROJECT_NAME', null);
        }
        if (to.name === 'Cluster Board Path') {
            store.commit('cluster/SHOW_CLUSTER_BOARD_FILE_UPLOAD', false);
            store.commit('cluster/UPDATE_BOARD');
        }

        next();
    });

    return router;
};
