import axios from 'axios';
import axiosRetry from 'axios-retry';
import { useAuth } from '@/auth/authPlugin';

axiosRetry(axios, {
    retries: 0,
    retryDelay: retryCount => {
        return retryCount * 3000;
    },
});

export const addRequestInterceptors = (axiosInstance, app) => {
    axiosInstance.interceptors.request.use(async config => {
        const authClient = useAuth();
        try {
            const token = await authClient.getAccessTokenSilently();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
        } catch (error) {
            await authClient.logout({
                async openUrl(url) {
                    window.location.href = document.location.origin;
                },
            });
        }

        return config;
    });
};

export const addResponseInterceptors = (axiosInstance, app) => {
    axiosInstance.interceptors.response.use(
        response => response,
        async error => {
            const authClient = useAuth();

            if (error.response && [401, 403].includes(error.response.status)) {
                // TODO: Commented out temporarily while dependent APIs are migrate to Moat infrastructure
                // await authClient.logout({
                //     async openUrl(url) {
                //         window.location.href = document.location.origin;
                //     },
                // });

                return Promise.reject(error);
            }

            if (error.response && !authClient.isAuthenticated.value) {
                location.reload(true);
            }

            return Promise.reject(error);
        }
    );
};

export default function(axiosInstance, app) {
    addRequestInterceptors(axiosInstance, app);
    addResponseInterceptors(axiosInstance, app);
}
