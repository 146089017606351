'use strict';

import axios from 'axios';
import { appConfig } from '@/common/appConfig';
import { copyEntry, entryTypes } from '@/common/copyHelper';
import { logger } from '@moatmetrics/vue-logger';

const API_URL = appConfig.apiUrl;

const projects = {
    namespaced: true,
    state: () => ({
        projectView: 'mine',
        isProjectModalShown: null,
        sortType: 'name',
        sortIsAscending: true,
        projectsLoading: null,
        showConfirmationModal: null,
        projectToDelete: null,
        projectToEdit: null,
        errorMsg: null,

        showProjectTable: true,
        userProjects: null,
        userPinnedProjects: null,
        currentProjectFilter: null,
        currentlyVisableProjects: [],
        visableProjectsCount: 0,
        totalNumProjects: 0,
        filteredResultsCount: 0,
        pageOffset: 0,
        queryParams: null,
    }),
    mutations: {
        UPDATE_PROJECT_VIEW: (state, view) => {
            state.projectView = view;
        },
        UPDATE_PROJECT_MODAL_SHOWN: (state, bool) => {
            state.isProjectModalShown = bool;
        },
        UPDATE_PROJECT_SORT_TYPE: (state, type) => {
            state.sortType = type;
        },
        UPDATE_PROJECT_SORT_DIRECTION: (state, direction) => {
            state.sortIsAscending = direction;
        },
        UPDATE_PROJECT_SEARCH_FILTER: (state, filter) => {
            state.currentProjectFilter = filter;
        },
        SET_USER_PROJECTS: (state, projects) => {
            state.userProjects = projects;
        },
        SET_PINNED_PROJECTS: (state, projects) => {
            state.userPinnedProjects = projects;
        },
        SET_FILTERED_RESULTS_COUNT: (state, count) => {
            state.filteredResultsCount = count;
        },
        SHOULD_SHOW_LOADER: (state, bool) => {
            state.projectsLoading = bool;
        },
        SHOW_CONFIMATION_MODAL: (state, id) => {
            state.showConfirmationModal = id;
        },
        PROJECT_TO_DELETE: (state, obj) => {
            state.projectToDelete = obj;
        },
        PROJECT_TO_EDIT: (state, project) => {
            state.projectToEdit = project;
        },
        SET_ONCE_PROJECT_LIST: (state, { projects, page }) => {
            if (page === 1) {
                state.currentlyVisableProjects = [];
            }

            state.currentlyVisableProjects.push(...projects);
            state.visableProjectsCount = state.currentlyVisableProjects
                ? state.currentlyVisableProjects.length
                : 0;
        },
        SET_TOTAL_PROJECTS: (state, numProjects) => {
            state.totalNumProjects = numProjects;
        },
        SET_SHOW_PROJECT_TABLE: (state, flag) => {
            state.showProjectTable = flag;
        },
        REMOVE_PROJECT_FROM_LIST: (state, projectPK) => {
            const beforeCount = state.visableProjectsCount;
            state.currentlyVisableProjects = state.currentlyVisableProjects.filter(
                project => project.project_pk !== projectPK
            );
            state.visableProjectsCount = state.currentlyVisableProjects
                ? state.currentlyVisableProjects.length
                : 0;

            if (beforeCount > state.visableProjectsCount) {
                state.totalNumProjects--;
                state.filteredResultsCount--;
                state.pageOffset--;
            }
        },
        REFRESH_PINNED_PROJECTS_LIST_FROM_UNPIN: state => {
            const beforeCount = state.visableProjectsCount;
            state.currentlyVisableProjects = state.currentlyVisableProjects.filter(
                project => project.user_pinned === true
            );
            state.visableProjectsCount = state.currentlyVisableProjects
                ? state.currentlyVisableProjects.length
                : 0;

            if (beforeCount > state.visableProjectsCount) {
                state.totalNumProjects--;
                state.pageOffset--;
            }
        },
        SET_ERROR_MSG: (state, msg) => {
            state.errorMsg = msg;
        },
        SET_QUERY_PARAMS: (state, params) => {
            state.queryParams = params;
        },
    },
    actions: {
        toShowProjectModal: (context, type) => {
            if (type === 'add') {
                context.commit('UPDATE_PROJECT_MODAL_SHOWN', type);
            } else {
                context.state.currentlyVisableProjects.filter(item => {
                    if (item.project_pk === type) {
                        context.commit('PROJECT_TO_EDIT', item);
                    }
                });
                context.commit('UPDATE_PROJECT_MODAL_SHOWN', type);
            }
        },
        setSortType: (context, type) => {
            context.commit('UPDATE_PROJECT_SORT_TYPE', type);
        },
        setAscendingDescending: (context, direction) => {
            context.commit('UPDATE_PROJECT_SORT_DIRECTION', direction);
        },
        loadProjectView: ({ commit }, view) => {
            commit('UPDATE_PROJECT_VIEW', view);
        },
        showConfirmationModal: (context, obj) => {
            context.commit('SHOW_CONFIMATION_MODAL', obj.name);
            context.commit('PROJECT_TO_DELETE', obj);
        },
        // API Calls
        async fetchProjects({ state, commit }, { queryParams, userPK }) {
            if (queryParams) {
                commit('SET_QUERY_PARAMS', queryParams);
            }

            let onlyMine = state.projectView === 'mine' ? 1 : 0;
            let onlyPinned = state.projectView === 'pinned' ? 1 : 0;
            let projects = null;
            try {
                commit('SHOULD_SHOW_LOADER', true);
                // CHANGE W/PINIA
                projects = await axios.get(
                    `${API_URL}/projects/user_context/${userPK}?PS=${state.queryParams.page_size}&PL=${state.queryParams.last_row_num}&only_user=${onlyMine}&only_pinned=${onlyPinned}`,
                    { params: state.queryParams }
                );
            } catch (ex) {
                logger.error(ex);
                return;
            }

            let userProjects = projects.data.page_data.filter(
                item => item.user_pk === userPK
            );
            let userPinnedProjects = projects.data.page_data.filter(
                item => item.user_pinned === true
            );
            commit('SET_TOTAL_PROJECTS', projects.data.total_count);
            commit(
                'SET_FILTERED_RESULTS_COUNT',
                projects.data.filtered_total_count
            );
            commit('SET_USER_PROJECTS', userProjects);
            commit('SET_PINNED_PROJECTS', userPinnedProjects);
            commit('SHOULD_SHOW_LOADER', false);

            if (state.projectView === 'mine') {
                commit('SET_ONCE_PROJECT_LIST', {
                    projects: userProjects,
                    page: state.queryParams.page,
                });
            } else if (state.projectView === 'pinned') {
                commit('SET_ONCE_PROJECT_LIST', {
                    projects: userPinnedProjects,
                    page: state.queryParams.page,
                });
            } else {
                commit('SET_ONCE_PROJECT_LIST', {
                    projects: projects.data.page_data,
                    page: state.queryParams.page,
                });
            }
        },
        async pinProject({}, { customParams, projectRef }) {
            try {
                let ret = await axios.post(
                    `${API_URL}/user_projects`,
                    customParams
                );
                if (ret.status === 200) {
                    projectRef.user_pinned = true;
                }
            } catch (ex) {
                logger.error(ex);
            }
        },
        async unpinProject(
            { rootState, state, commit },
            { projectPK, projectRef }
        ) {
            try {
                let ret = await axios.delete(
                    `${API_URL}/user_projects?user_pk=${rootState.user.userData.user_pk}&project_pk=${projectPK}`
                );
                if (ret.status === 204) {
                    projectRef.user_pinned = false;
                    if (state.projectView === 'pinned') {
                        commit('REFRESH_PINNED_PROJECTS_LIST_FROM_UNPIN');
                    }
                }
            } catch (ex) {
                logger.error(ex);
            }
        },
        async addProject({ commit }, customParams) {
            try {
                var res = await axios.post(`${API_URL}/projects`, customParams);
            } catch (ex) {
                if (ex.status === 400) {
                    commit('SET_ERROR_MSG', ex.data.message);
                    setTimeout(() => {
                        commit('SET_ERROR_MSG', null);
                    }, 5000);
                }
                commit('UPDATE_PROJECT_MODAL_SHOWN', false);
                commit('UPDATE_PROJECT_VIEW', 'all');
                return;
            }

            commit('UPDATE_PROJECT_MODAL_SHOWN', false);
            commit('UPDATE_PROJECT_VIEW', 'all');
            return res.data.project_pk;
        },
        async copyProject({ commit }, passedParams) {
            var res = await copyEntry(
                entryTypes.PROJECT,
                passedParams.id,
                passedParams
            );

            if (res.status === 500 || res.status === 400) {
                commit('SET_ERROR_MSG', res.data.message);
                setTimeout(() => {
                    commit('SET_ERROR_MSG', null);
                }, 5000);
            }

            commit('UPDATE_PROJECT_MODAL_SHOWN', false);
            commit('UPDATE_PROJECT_VIEW', 'all');
        },
        async deleteProject({ commit }, id) {
            try {
                await axios.delete(`${API_URL}/projects/${id}`);
            } catch (ex) {
                logger.error(ex);
                return;
            }
            commit('REMOVE_PROJECT_FROM_LIST', id);
            commit('SHOW_CONFIMATION_MODAL', null);
        },
        async editProject({ commit }, passedParams) {
            let paramsObj = {
                name: passedParams.name,
                description: passedParams.description,
                public_project: passedParams.public_project,
            };
            try {
                await axios.put(
                    `${API_URL}/projects/${passedParams.id}`,
                    paramsObj
                );
            } catch (ex) {
                if (ex.status === 400) {
                    commit('SET_ERROR_MSG', ex.data.message);
                    setTimeout(() => {
                        commit('SET_ERROR_MSG', null);
                    }, 5000);
                }
                commit('UPDATE_PROJECT_MODAL_SHOWN', false);
                return;
            }

            commit('UPDATE_PROJECT_MODAL_SHOWN', false);
        },
    },
};

export default projects;
