'use strict';

import axios from 'axios';
import { appConfig } from '@/common/appConfig';
import { copyEntry, entryTypes } from '@/common/copyHelper';
import { logger } from '@moatmetrics/vue-logger';

const API_URL = appConfig.apiUrl;

const search = {
    namespaced: true,
    state: {
        currentProject: null,
        currentProjectQuery: null,
        currentProjectQuerySet: null,
        currentPK: null,
        projectQueries: [],
        showConfirmationModal: false,
        queryToDelete: null,
        loadingQueries: false,
        loadingClusters: false,
        loadingSimilarities: false,
        queriesError: false,
        clustersError: false,
        similaritiesError: false,
        loadingQuery: false,
        isQueryModalShown: null,
        queryToEdit: null,
        errorMsg: null,
        // Table
        tableType: 'assignee',
        clusterRuns: null,
        similarityRuns: null,
        searchTableKey: 0,
        queryPageRouteWatchParams: null,
    },
    mutations: {
        ROUTE_WATCH_PARAM_CHANGE: (state, params) => {
            state.queryPageRouteWatchParams = params;
        },
        BUMP_SEARCH_TABLE_KEY: state => {
            state.searchTableKey++;
        },
        SET_PROJECT: (state, project) => {
            state.currentProject = project;
        },
        SET_PROJECT_QUERIES: (state, queries) => {
            state.projectQueries = queries;
        },
        PROJECT_QUERY_DETAILS: (state, data) => {
            state.currentProjectQuery = data;
            state.currentProjectQuerySet =
                data.sets[data.sets.length - 1].set_pk;
        },
        SHOW_CONFIMATION_MODAL: (state, id) => {
            state.showConfirmationModal = id;
        },
        QUERY_TO_DELETE: (state, obj) => {
            state.queryToDelete = obj;
        },
        QUERIES_LOADING: (state, bool) => {
            state.loadingQueries = bool;
        },
        QUERIES_ERROR: (state, bool) => {
            state.queriesError = bool;
        },
        CLUSTERS_LOADING: (state, bool) => {
            state.loadingClusters = bool;
        },
        CLUSTERS_ERROR: (state, bool) => {
            state.clustersError = bool;
        },
        SIMILARITIES_LOADING: (state, bool) => {
            state.loadingSimilarities = bool;
        },
        SIMILARITIES_ERROR: (state, bool) => {
            state.similaritiesError = bool;
        },
        SET_QUERY_LOADING: (state, bool) => {
            state.loadingQuery = bool;
        },
        UPDATE_QUERY_MODAL_SHOWN: (state, type) => {
            state.isQueryModalShown = type;
        },
        QUERY_TO_EDIT: (state, project) => {
            state.queryToEdit = project;
        },
        SET_ERROR_MSG: (state, msg) => {
            state.errorMsg = msg;
        },
        SET_PROJECT_PK: (state, id) => {
            state.currentPK = id;
        },
        SET_TABLE_TAB: (state, type) => {
            state.tableType = type;
        },
        CLUSTER_RUNS: (state, data) => {
            if (!state.clusterRuns) {
                state.clusterRuns = data;
            } else {
                state.clusterRuns.page_data.push(...data.page_data);
            }
        },
        CLEAR_CLUSTER_RUNS: state => {
            state.clusterRuns = null;
        },
        CLUSTER_RUN_STATUS: (state, { newStatus, jobIndex }) => {
            state.clusterRuns.page_data[jobIndex].job_status = newStatus;
        },
        SIMILARITY_RUNS: (state, data) => {
            if (!state.similarityRuns) {
                state.similarityRuns = data;
            } else {
                state.similarityRuns.page_data.push(...data.page_data);
            }
        },
        CLEAR_SIMILARITY_RUNS: state => {
            state.similarityRuns = null;
        },
        SIMILARITY_RUN_STATUS: (state, { newStatus, jobIndex }) => {
            state.similarityRuns.page_data[jobIndex].job_status = newStatus;
        },
    },
    actions: {
        async setProject({ commit, dispatch }, id) {
            if (!id) return;

            const project = await dispatch('fetchSingleProject', id); // Maybe could cause security hole... anybody could see the project if they know the projectpk
            commit('SET_PROJECT', project);

            commit('SET_PROJECT_QUERIES', []);

            return project;
        },
        showConfirmationModal: ({ commit }, obj) => {
            commit('SHOW_CONFIMATION_MODAL', obj.name);
            commit('QUERY_TO_DELETE', obj);
        },
        toShowQueryModal: ({ commit, state }, type) => {
            if (type === 'add') {
                commit('UPDATE_QUERY_MODAL_SHOWN', type);
            } else {
                const target = state.projectQueries.find(
                    item => item.query_pk === type
                );
                commit('QUERY_TO_EDIT', target);
                commit('UPDATE_QUERY_MODAL_SHOWN', type);
            }
        },
        async getAllProjectQueries({ commit }, projectPK) {
            commit('QUERIES_LOADING', true);
            try {
                // CHANGE W/PINIA
                const allQueries = await axios.get(
                    `${API_URL}/queries/${projectPK}`
                );
                if (allQueries) {
                    commit('SET_PROJECT_QUERIES', allQueries.data);
                }
            } catch (ex) {
                logger.error('error'); // global alert box error or something...
                commit('QUERIES_ERROR', true);
            } finally {
                commit('QUERIES_LOADING', false);
            }
        },
        async createNewQuery({ commit }, params) {
            try {
                var res = await axios.post(`${API_URL}/query`, params);
            } catch (ex) {
                if (ex.status === 400) {
                    commit('SET_ERROR_MSG', ex.data.message);
                    setTimeout(() => {
                        commit('SET_ERROR_MSG', null);
                    }, 5000);
                }

                commit('UPDATE_QUERY_MODAL_SHOWN', false);
                return;
            }

            commit('UPDATE_QUERY_MODAL_SHOWN', false);
            commit('BUMP_SEARCH_TABLE_KEY');
            return res.data.query_pk;
        },
        async copyQuery({ dispatch, commit }, passedParams) {
            var res = await copyEntry(
                entryTypes.QUERY,
                passedParams.id,
                passedParams
            );

            if (res.status === 500) {
                commit('SET_ERROR_MSG', res.data.message);
                setTimeout(() => {
                    commit('SET_ERROR_MSG', null);
                }, 5000);
            }

            dispatch('getAllProjectQueries', passedParams.project_pk);
            commit('UPDATE_QUERY_MODAL_SHOWN', false);
        },
        async getQueryDetails(
            { commit, dispatch },
            { queryPK, doNav = false }
        ) {
            commit('SET_QUERY_LOADING', true);
            try {
                // CHANGE W/PINIA
                const query = await axios.get(`${API_URL}/query/${queryPK}`);
                if (query) {
                    commit('PROJECT_QUERY_DETAILS', query.data);

                    if (query.data.sets && query.data.sets.length > 0) {
                        await dispatch(
                            'entity/getPreviouslySelectedEntities',
                            queryPK,
                            { root: true }
                        );
                        await dispatch(
                            'assignee/getPreviouslySelectedAssignees',
                            queryPK,
                            { root: true }
                        );
                        await dispatch(
                            'publication/getSavedSearch',
                            { query_pk: queryPK },
                            { root: true }
                        );
                    }

                    let subRoute = 'entity';
                    if (doNav) {
                        subRoute = doNav;
                        commit('SET_TABLE_TAB', subRoute);
                    } else {
                        if (query.data.sets) {
                            let patents = 0;
                            let assignees = 0;
                            let entities = 0;

                            query.data.sets.filter(item => {
                                if (item.set_type === 'Patent') {
                                    patents++;
                                }
                                if (item.set_type === 'Assignee') {
                                    assignees++;
                                }
                                if (item.set_type === 'Entity') {
                                    entities++;
                                }
                            });

                            if (patents > 0) {
                                subRoute = 'pub';
                            }

                            if (assignees > 0) {
                                subRoute = 'assignee';
                            }

                            if (entities > 0) {
                                subRoute = 'entity';
                            }
                        }
                        commit('SET_TABLE_TAB', subRoute);
                    }
                }
            } catch (ex) {
                logger.error('error'); // global alert box error or something...\
                return;
            } finally {
                commit('SET_QUERY_LOADING', false);
            }
        },
        async deleteQuery({ dispatch, commit, rootState }, id) {
            try {
                await axios.delete(`${API_URL}/query/${id}`);
            } catch (ex) {
                logger.error(ex);
                return;
            }
            dispatch('getAllProjectQueries', rootState.projectPK);

            commit('SHOW_CONFIMATION_MODAL', null);
        },
        async editQuery({ dispatch, commit, rootState }, passedParams) {
            let paramsObj = {
                name: passedParams.name,
                description: passedParams.description
                    ? passedParams.description
                    : '',
            };
            try {
                await axios.put(
                    `${API_URL}/query/${passedParams.id}`,
                    paramsObj
                );
            } catch (ex) {
                if (ex.status === 500) {
                    commit('SET_ERROR_MSG', ex.data.message);
                    setTimeout(() => {
                        commit('SET_ERROR_MSG', null);
                    }, 5000);
                }

                commit('UPDATE_QUERY_MODAL_SHOWN', false);
                return;
            }
            dispatch('getAllProjectQueries', rootState.projectPK);
            commit('BUMP_SEARCH_TABLE_KEY');
            commit('UPDATE_QUERY_MODAL_SHOWN', false);
        },
        async fetchSingleProject(context, projectPK) {
            try {
                // CHANGE W/PINIA
                var projects = await axios.get(
                    `${API_URL}/projects/${projectPK}`
                );
            } catch (ex) {
                logger.error(ex);
                return;
            }

            return projects.data;
        },
        async getProjectClusterRuns({ commit }, { projectPK, params }) {
            commit('CLUSTERS_LOADING', true);
            try {
                // CHANGE W/PINIA
                const results = await axios.get(
                    `${API_URL}/cluster_run?project_pk=${projectPK}`,
                    { params }
                );
                if (results?.data) {
                    commit('CLUSTER_RUNS', results.data);
                }
            } catch (ex) {
                logger.error(ex);
                commit('CLUSTERS_ERROR', true);
            } finally {
                commit('CLUSTERS_LOADING', false);
            }
        },
        async getProjectSimilarityRuns({ commit }, { projectPK, params }) {
            commit('SIMILARITIES_LOADING', true);
            try {
                // CHANGE W/PINIA
                const results = await axios.get(
                    `${API_URL}/similarity_run?project_pk=${projectPK}`,
                    { params }
                );
                if (results?.data) {
                    commit('SIMILARITY_RUNS', results.data);
                }
            } catch (ex) {
                logger.error(ex);
                commit('SIMILARITIES_ERROR', true);
            } finally {
                commit('SIMILARITIES_LOADING', false);
            }
        },
        setTableTab: (context, type) => {
            context.commit('SET_TABLE_TAB', type);
        },
    },
};

export default search;
