import axios from 'axios';
import { appConfig } from '@/common/appConfig';

const baseUrl = appConfig.external.entitySvcUrl;

/**
 * API GET all entities that match search term
 * @param {String} searchTerm
 * @param {Object} params values to support sorting, filtering and paging
 * @returns API results
 */
export function searchEntities(searchTerm, params) {
    return axios.get(
        `${baseUrl}/entities/search/${encodeURIComponent(searchTerm)}`,
        {
            params,
        }
    );
}

/**
 * API GET entity details
 * @param {Number} entityPk
 * @returns entity details
 */
export function getEntityDetailsPeek(entityPk) {
    return axios.get(`${baseUrl}/entity/${entityPk}/popover-details`);
}

/**
 * API GET entity details
 * @param {Number} entityPk
 * @returns entity details
 */
export function getEntityDetails(entityPk) {
    return axios.get(`${baseUrl}/entity/${entityPk}`);
}

/**
 * API POST to get suggestion source id
 * @param {String} email
 * @returns object with the suggestion source id
 */
export function setSuggestionDetails(email) {
    return axios.post(`${baseUrl}/suggestion/suggestion_source`, {
        source_type: 'user',
        source_name: email,
    });
}

/**
 * API POST new classification value
 * @param {Number} entityPk
 * @param {String} operatingClassification either 'OC' or 'NPE'
 * @param {Number} suggestionSourceId value from suggestion/suggestion_source endpoint
 */
export function setOperatingClassification(
    entityPk,
    operatingClassification,
    suggestionSourceId
) {
    return axios.post(`${baseUrl}/entity/operating_classification`, {
        aon_entity_pk: entityPk,
        operating_classification: operatingClassification,
        suggestion_source_id: suggestionSourceId,
    });
}

/**
 * API GET all source entities referencing the entity pk
 * @param {Number} entityPk
 * @param {Object} params values that support sorting and filtering
 */
export function getSourceEntities(entityPk, params) {
    return axios.get(`${baseUrl}/entity/${entityPk}/source-entities`, {
        params,
    });
}
