import JSZip from 'jszip';

const MAX_UPLOAD_FILE_SIZE = 1024 * 1024 * 1024;

export const checkIfUploadFileTooLarge = async patentFile => {
    let fileSize = 0;
    if (patentFile.name.endsWith('.zip')) {
        let zip = new JSZip();
        await zip.loadAsync(patentFile);
        zip.forEach(function(relativePath, file) {
            if (!file.dir) {
                let size = zip.file(relativePath)._data.uncompressedSize;
                if (size < 0) {
                    // Wraparound fix
                    size = -size + (2 ** 31 - 1);
                }
                fileSize += size;
            }
        });
    } else {
        fileSize = patentFile.size;
    }
    return fileSize > MAX_UPLOAD_FILE_SIZE;
};

export const zipPatentFile = async patentFile => {
    let formData = new FormData();
    if (patentFile.name.endsWith('.zip')) {
        formData.append('patent_file', patentFile);
    } else {
        let zipName = `${patentFile.name}.zip`;
        let zip = new JSZip();
        zip.file(patentFile.name, patentFile);
        let zipBlob = await zip.generateAsync({
            type: 'blob',
            compression: 'DEFLATE',
            compressionOptions: { level: 1 },
        });
        let pFile = new File([zipBlob], zipName);
        formData.append('patent_file', pFile); // patentFile)
    }
    return formData;
};

export const downloadFileFromUrl = async (fileUrl, filename) => {
    // to use custom file name, need to download and then "download" again with filename from browser
    await fetch(fileUrl)
        .then(res => {
            return res.blob();
        })
        .then(blob => {
            var a = document.createElement('a');
            a.href = URL.createObjectURL(blob);
            a.setAttribute('download', filename);
            a.click();
        });
};
