import patentSimilarity from './patent-similarity';
import history from './history';
import model from './model';
import auth from './auth';

export default {
    patentSimilarity,
    history,
    model,
    auth,
};
