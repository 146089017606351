import axios from 'axios';
import { appConfig } from '@/common/appConfig';

export const entryTypes = {
    PROJECT: 'projects',
    QUERY: 'query',
};

export const copyEntry = async (entryType, entryPK, paramsObj) => {
    if (!entryType) {
        throw new Error('Entry type is not defined.');
    }
    try {
        var res = await axios.post(
            `${appConfig.apiUrl}/${entryType}/${entryPK}/copy`,
            paramsObj
        );
    } catch (ex) {
        return ex;
    }

    return res;
};
