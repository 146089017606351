<script setup>
import { onMounted } from 'vue';
import { useAuth } from '@/auth/authPlugin';

const authClient = useAuth();

onMounted(() => {
    authClient.logout({
        async openUrl(url) {
            window.location.href = document.location.origin;
        },
    });
});
</script>

<template>
    <div id="logout"></div>
</template>

<style scoped lang="scss"></style>
