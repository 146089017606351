import { apiGet } from '@/common/apiCommunicator';

let assigneeList = [];

const asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
};

const setAssigneeNums = ass => {
    if (!assigneeList.includes(ass)) {
        assigneeList.push(ass);
    }
    return assigneeList.indexOf(ass);
};

const setScore = score => {
    if (score > 100) {
        return 100;
    } else if (score <= 100 && score > 0) {
        return score;
    } else {
        return 0;
    }
};

export async function getClusterVisualizationData(
    clusterRunPk,
    errorHandler = null
) {
    let urlResp = await apiGet(
        `cluster/${clusterRunPk}/visualization/results`,
        errorHandler
    );

    if (!urlResp.data || !urlResp.data.url) {
        // FIXME Return null here instead of erroring since we want to be able to retry the load for now
        // if (errorHandler) {
        //     return errorHandler(urlResp)
        // }
        // throw Error('Api did not return url for cluster data.')
        return null;
    }
    let allVectorData = [];
    await asyncForEach(urlResp.data.vectors, async item => {
        await fetch(item.data_url)
            .then(response => response.json())
            .then(jsonData => {
                allVectorData.push({
                    data: jsonData,
                    type: item.name,
                    vectorPK: item.cluster_run_vector_pk,
                });
            });
    });
    return allVectorData;
}

export async function parseVisualizationData(data) {
    let mappedData = [];
    let length = data.length;

    let xMax = 0;
    let xMin = 0;
    let yMax = 0;
    let yMin = 0;
    for (let i = 0; i < length; i++) {
        mappedData.push({
            patent_id: data[i].patent_id || data[i]['Publication Number'],
            timestamp: isNaN(
                new Date(
                    data[i].priority_dt || data[i]['Priority Date']
                ).getTime()
            )
                ? 0
                : new Date(
                      data[i].priority_dt || data[i]['Priority Date']
                  ).getTime(),
            date: data[i].priority_dt || data[i]['Priority Date'],
            title: data[i].title || data[i]['Title'],
            assignee:
                (data[i].assignees_grouped || data[i]['Assignee Group']
                    ? data[i].assignees_grouped || data[i]['Assignee Group']
                    : data[i].assignees || data[i]['All Assignees']) ||
                'Unassigned',
            assigneeID: setAssigneeNums(
                data[i].assignees_grouped || data[i]['Assignee Group']
                    ? data[i].assignees_grouped || data[i]['Assignee Group']
                    : data[i].assignees || data[i]['All Assignees']
            ),
            x: Number(data[i].x || data[i]['X Coordinate']),
            y: Number(data[i].y || data[i]['Y Coordinate']),
            score: setScore(
                Number(data[i].claim_score || data[i]['Claim Score'])
            ),
            clusterNum: isNaN(
                parseInt(data[i].cluster_number || data[i]['cluster number'])
            )
                ? 0
                : data[i].cluster_number,
            clusterKeyWords: data[i].keywords
                ? data[i].keywords.join(', ')
                : 'Outlier' || data[i]['Cluster Keywords'],
            clusterLabel: data[i].label || data[i]['Cluster Label'],
            keyId: isNaN(
                parseInt(data[i].cluster_number || data[i]['cluster number'])
            )
                ? 0
                : data[i].cluster_number,
            group: null,
        });

        if (yMax < data[i].y) {
            yMax = data[i].y;
        }
        if (yMin > data[i].y) {
            yMin = data[i].y;
        }

        if (xMax < data[i].x) {
            xMax = data[i].x;
        }
        if (xMin > data[i].x) {
            xMin = data[i].x;
        }
    }
    let returnObj = {
        finalData: mappedData,
        yMax: yMax,
        yMin: yMin,
        xMax: xMax,
        xMin: xMin,
    };
    return returnObj;
}
