// Inits
import { createApp } from 'vue'; // CHANGE
import App from './App.vue';
import { createPinia } from 'pinia';
import { createRouter } from './router';
import { routes } from './router/routes';
import { store } from './store';
import { appConfig } from '@/common/appConfig';

// Standard Imports
import axios from 'axios';
import VueAxios from 'vue-axios';
import initializeAxios from '@/common/axiosInitializer';
import GlobalTablePlugin from '@moatmetrics/global_table';
import Multiselect from 'vue-multiselect';
import pluralize from 'pluralize';
import { createI18n } from 'vue-i18n';
import messages from '@/i18n';
import mitt from 'mitt';
import FloatingVue from 'floating-vue';

import armoryFontAwesomeIcons from '@moatmetrics/armory/src/util/fontawesome.js';
import armoryDuplexFontAwesomeIcons from '@moatmetrics/armory_duplex/src/util/fontawesome.js';
import clusterBoardFontAwesomeIcons from '@moatmetrics/global_cluster_board/src/util/fontawesome.js';
import localFontAwesomeIcons from '@/lib/fontawesome.js';
import { plugin as loggingPlugin } from '@moatmetrics/vue-logger';

const fontAwesomeMergedIcons = {
    ...armoryFontAwesomeIcons,
    ...localFontAwesomeIcons,
    ...clusterBoardFontAwesomeIcons,
    ...armoryDuplexFontAwesomeIcons,
};

import { plugin as unleashPlugin } from '@unleash/proxy-client-vue';

// Mixins/Custom

//Global
import AonContainer from '@moatmetrics/armory/src/components/base/grid/AonContainer.vue';
import AonRow from '@moatmetrics/armory/src/components/base/grid/AonRow.vue';
import AonCol from '@moatmetrics/armory/src/components/base/grid/AonCol.vue';
import ClusterBoard from '@moatmetrics/global_cluster_board/src/components/board/ClusterBoard.vue';

// Pinia Store
const pinia = createPinia();

// CSS
import 'floating-vue/dist/style.css';
import VueApexCharts from 'vue3-apexcharts';
import AuthPlugin from '@/auth/authPlugin';

// Create Application - first thing after imports
const app = createApp(App);

app.use(loggingPlugin, {
    rumEnabled: appConfig.logging.rumEnabled,
    environment: appConfig.env,
    appVersion: appConfig.version,
    loggingConfiguration: {
        level: appConfig.logging.level,
        handlers: appConfig.logging.handlers,
    },
    grafana: {
        appName: appConfig.logging.grafanaAppName,
        url: appConfig.logging.grafanaUrl,
        tracingEnabled: appConfig.logging.tracingEnabled,
    },
});

const router = createRouter(app, [...routes]);

initializeAxios(axios, app);

// Modifiers/Sets
FloatingVue.options.popperClass = 'aon-tooltip-styles';
app.config.performance = true;
// - May want to move away from an evenHub later, replace with template refs or provide/inject(?)
const emitter = mitt();
app.config.globalProperties.eventHub = emitter;
app.provide('eventBus', emitter);
const i18n = new createI18n({
    fallbackLocale: 'en',
    locale: 'en',
    messages,
});

// Filters
app.config.globalProperties.$filters = {
    pluralize(value, number) {
        return pluralize(value, Math.floor(number));
    },
};

// Make global components
app.component('AonContainer', AonContainer);
app.component('AonRow', AonRow);
app.component('AonCol', AonCol);
app.component('MultiSelect', Multiselect);
app.component('ClusterBoard', ClusterBoard);
app.component('FontAwesomeIcon', fontAwesomeMergedIcons);

// Directives
app.directive('click-outside', {
    beforeMount: (el, binding) => {
        el.clickOutsideEvent = event => {
            if (!(el === event.target || el.contains(event.target))) {
                binding.value();
            }
        };
        document.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted: el => {
        document.removeEventListener('click', el.clickOutsideEvent);
    },
});
app.directive('focus', {
    beforeMount: el => {
        el.focus();
    },
});

//Provides
app.provide('config', appConfig);
app.provide('gProps', app.config.globalProperties);

// Start Application
app.use(unleashPlugin, {
    config: {
        url: appConfig.unleash.url,
        clientKey: appConfig.unleash.clientKey,
        refreshInterval: appConfig.unleash.refreshInterval,
        appName: appConfig.unleash.appName,
        environment: appConfig.unleash.env,
    },
});
app.use(VueApexCharts);
app.use(GlobalTablePlugin);
app.use(VueAxios, axios);
app.use(FloatingVue);
app.use(i18n);
app.use(router);
app.use(AuthPlugin, {
    domain: appConfig.auth.domain,
    clientId: appConfig.auth.clientId,
    redirectUri: new URL(
        router.resolve({ name: 'Login Callback' }).href,
        window.location.origin
    ).href,
    audience: appConfig.auth.audience,
});
app.use(store);
app.use(pinia);
app.mount('#app');
