import { createStore } from 'vuex';

import pluralize from 'pluralize';
import axios from 'axios';
import { logger } from '@moatmetrics/vue-logger';

import entity from './modules/entity';
import user from './modules/user';
import projects from './modules/projects';
import query from './modules/query';
import assignee from './modules/assignee';
import similarity from './modules/similarity';
import cluster from './modules/cluster';
import publication from './modules/publication';
import apiConfig from '@/store/modules/apiConfig';

import { appConfig } from '@/common/appConfig';
import { downloadFileFromUrl } from '@/common/files';
import { apiGet, apiPost } from '@/common/apiCommunicator';
import { waitForJob } from '@/services/jobService';

const API_URL = appConfig.apiUrl;

export const actions = {
    clearTabStates({ commit }) {
        commit('entity/CLEAR_SEARCH_STATE');
        commit('assignee/CLEAR_SELECTED_RESULTS');
        commit('assignee/CLEAR_SEARCH_RESULTS');
        commit('publication/CLEAR_STATE');
    },
    errorMsg(context, msg) {
        context.commit('SET_ERROR_MSG', msg);
        setTimeout(() => {
            context.commit('SET_ERROR_MSG', null);
            context.commit('SET_SHOW_IN_APP', true);
        }, 5000);
    },
    async saveAssigneeSearch(context, params) {
        let queryParams = {
            user_pk: context.rootState.user.userData.user_pk,
            data: params.id_values,
        };

        context.commit('assignee/SHOULD_SHOW_LOADER', true);

        try {
            var results = await axios.put(
                `${API_URL}/query/${params.query_pk}/assignees`,
                queryParams
            );
        } catch (ex) {
            logger.error(ex);
            return;
        }
        context.commit('assignee/SET_DIRTY_STATE', false);

        context.commit('assignee/QUERY_SAVED', true); // Trigger success alert

        if (params.type === 'cluster') {
            params.clusterFlag = true;
            context.commit('cluster/SET_CLUSTER_PROCESS_ACTIVE', true);
        } else if (params.type === 'similarity') {
            context.commit('similarity/SET_SIMILARITY_PROCESS_RUNNING', true);
        } else if (params.type === 'assignExport') {
            await context.dispatch('assignee/export', {
                setPk: results.data.set_pk,
                projectPk: context.rootState.projectPK,
                userPk: context.rootState.user.userData.user_pk,
                fileName: params.fileName,
            });
        }

        context.commit('assignee/SHOULD_SHOW_LOADER', false);
        setTimeout(() => {
            context.commit('assignee/QUERY_SAVED', false); // Set back to false here so that success alert will fade away
        }, 250);

        // Early exit if only saving
        if (!params.type || params.type === 'assignExport') {
            return;
        }

        await context.dispatch('getAssigneeJobRunParams', params);
        context.commit('SET_SHOW_EXPORT', false);
    },
    async getAssigneeJobRunParams(context, params) {
        try {
            // CHANGE W/PINIA
            var runParams = await axios.get(
                `${API_URL}/query/${params.query_pk}/assignees`
            );
        } catch (ex) {
            logger.error(ex);

            return;
        }

        let finalRunParams = {
            project_pk: context.rootState.projectPK,
            user_pk: runParams.data.page_data[0].user_pk,
            aggregation_type: params.aggregation_type,
            index_type: params.index_type ? params.index_type : 'snowflake',
            algorithm_type: params.algorithm_type,
            search_size: params.search_size,
            sample_size: params.sample_size,
            output_type: params.output_type,
            jurisdictions: params.jurisdictions,
            aon_status: params.aon_status,
            expiration_dt_start: params.expiration_dt_start,
            expiration_dt_end: params.expiration_dt_end,
            filing_dt_start: params.filing_dt_start,
            filing_dt_end: params.filing_dt_end,
            priority_dt_start: params.priority_dt_start,
            priority_dt_end: params.priority_dt_end,
            kind: params.kind,
            publication_type: params.publication_type,
        };

        if (params.clusterFlag) {
            finalRunParams.set_pks = [runParams.data.page_data[0].set_pk];
            context.dispatch('cluster/runClusteringService', finalRunParams);
        } else {
            finalRunParams.set_pk = runParams.data.page_data[0].set_pk;
            context.commit('similarity/SET_SIMILARITY_RUN_TYPE', 'assignee');
            context.commit(
                'similarity/SET_SIMILARITY_OUTPUT_TYPE',
                finalRunParams.output_type
                    ? finalRunParams.output_type
                    : 'assignee'
            );
            context.dispatch('similarity/runSimilarity', finalRunParams);
        }
    },
    async getPatentJobRunParams(context, params) {
        context.commit('publication/SET_SEARCH_ERROR', null);
        context.commit('publication/SET_SAVE_WARNING', null);
        context.commit('publication/SET_AMBIGUOUS_WARNING', null);

        let numIdsNotFound = 0;
        let numAmbiguousIdsFound = 0;
        if (params.userInputChanged || params.saveButton) {
            context.commit('publication/QUERIES_LOADING', true);

            let data = [];
            params.pubNums.forEach(item => data.push({ patent_id: item }));

            let paramsObj = {
                user_pk: params.userPk,
                data: data,
            };

            try {
                var results = await axios.put(
                    `${API_URL}/query/${params.queryPk}/patents`,
                    paramsObj
                );
            } catch (ex) {
                logger.error(ex);
                return;
            } finally {
                context.commit('publication/QUERIES_LOADING', false);
            }

            if (results.status !== 201) {
                context.commit(
                    'publication/SET_SEARCH_ERROR',
                    'Error occured while saving query'
                );
                context.commit('publication/QUERY_SAVED', false);
                return;
            }

            context.commit('publication/QUERY_SAVED', true); // Trigger success alert

            await context.dispatch('publication/getSavedSearch', {
                query_pk: params.queryPk,
                shouldRun: params.shouldRun,
            });

            numIdsNotFound =
                results.data && results.data.ids_not_found
                    ? results.data.ids_not_found.length
                    : 0;

            numAmbiguousIdsFound =
                results.data && results.data.ambiguous_ids
                    ? results.data.ambiguous_ids.length
                    : 0;

            if (numAmbiguousIdsFound > 0) {
                context.commit(
                    'publication/SET_AMBIGUOUS_PUBLICATION_NUMBERS',
                    results.data.ambiguous_ids
                );
                context.commit(
                    'publication/SET_AMBIGUOUS_WARNING',
                    '(' +
                        results.data.ambiguous_ids.length +
                        ') Publication numbers are ambiguous.'
                );
            } else {
                context.commit(
                    'publication/SET_AMBIGUOUS_PUBLICATION_NUMBERS',
                    null
                );
            }

            if (numIdsNotFound > 0) {
                context.commit(
                    'publication/SET_INVALID_PUBLICATION_NUMBERS',
                    results.data.ids_not_found
                );
                context.commit(
                    'publication/SET_SAVE_WARNING',
                    results.data.ids_not_found.length +
                        ' Publication ' +
                        pluralize('number', results.data.ids_not_found.length) +
                        ' could not be found.'
                );
            } else {
                context.commit(
                    'publication/SET_INVALID_PUBLICATION_NUMBERS',
                    null
                );
            }

            context.commit('publication/QUERY_SAVED', false); // Set back to false here so that success alert will fade away
        } else {
            for (let patent of context.rootState.publication.patents) {
                numIdsNotFound += !patent.record_found ? 1 : 0;
            }
        }

        switch (params.runJobType) {
            case 'similarity':
                let finalRunParams = {
                    set_pk: context.rootState.publication.setPk,
                    project_pk: params.projectPK,
                    user_pk: params.userPk,
                };
                if (params.similarityParams) {
                    finalRunParams = {
                        ...finalRunParams,
                        ...params.similarityParams,
                    };
                }
                context.commit(
                    'similarity/SET_SIMILARITY_PROCESS_RUNNING',
                    true
                );
                context.commit('similarity/SET_SIMILARITY_RUN_TYPE', 'patent');
                context.commit(
                    'similarity/SET_SIMILARITY_OUTPUT_TYPE',
                    finalRunParams.output_type
                        ? finalRunParams.output_type
                        : 'patent'
                );
                await context.dispatch(
                    'similarity/runSimilarity',
                    finalRunParams
                );
                break;

            case 'cluster':
                if (params.pubNums.length - numIdsNotFound < 15) {
                    await context.dispatch(
                        'errorMsg',
                        'Clustering requires at least 15 publication numbers.'
                    );
                    return;
                }

                await context.dispatch('cluster/runClusteringService', {
                    set_pks: [context.rootState.publication.setPk],
                    project_pk: params.projectPK,
                    user_pk: params.userPk,
                });
                break;

            case 'patentExport':
                await context.dispatch('publication/export', {
                    setPk: context.rootState.publication.setPk,
                    projectPk: params.projectPK,
                    userPk: params.userPk,
                    fileName: params.exportFileName,
                });
        }
    },

    async exportData(context, params) {
        let body = {
            user_pk: params.userPk,
            project_pk: params.projectPk,
            export_types: params.exportTypes,
            selected_only: params.selectedOnly,
            results_count_limit: params.resultsCountLimit,
            file_name: params.fileName,
            similar_patents: params.similarPatents,
        };

        let success = params.success;
        let failure = params.failure;

        let vectorTypeOfficial =
            context.rootState.cluster.currentVectorType === 'market'
                ? 'market_shallow_624'
                : 'patent_cpc_512';
        let urlRequestMap = {
            GENERIC: `set/${params.servicePk}/export/compile`,
            SIMILARITY: `similarity_run/${params.servicePk}/export/compile`,
            CLUSTER: `cluster_run/${
                params.servicePk
            }/job_parts/${vectorTypeOfficial}/reports/${
                context.rootState.cluster.clusterResults
                    ? context.rootState.cluster.clusterResults.report_id
                    : ''
            }/export/compile`,
        };

        let compileResp = await apiPost(
            urlRequestMap[params.serviceType],
            body
        );

        if (!compileResp || compileResp.status !== 201) {
            return failure();
        }

        let servicePk = null;

        if (params.serviceType === 'GENERIC') {
            servicePk = compileResp.data.set_export_pk;
        } else {
            servicePk = params.servicePk;
        }

        await waitForJob(
            compileResp.data.job_pk,
            async () => {
                try {
                    context.dispatch('downloadExportFile', {
                        serviceType: params.serviceType,
                        servicePk: servicePk,
                        failure: failure,
                        fileName: params.fileName,
                    });
                    success();
                } catch {
                    failure();
                }
            },
            failure
        );
    },
    async downloadExportFile(context, params) {
        let serviceType = params.serviceType;
        let servicePk = params.servicePk;
        let errorHandler = params.failure;
        let fileName = params.fileName;
        let payloadUrlPropName = params.payloadUrlPropName || 'url';

        let vectorTypeOfficial =
            context.rootState.cluster.currentVectorType === 'market'
                ? 'market_shallow_624'
                : 'patent_cpc_512';

        let urlRequestMap = {
            GENERIC: `set_export/${servicePk}/url`,
            SIMILARITY: `similarity_run/${servicePk}/export/results`,
            CLUSTER: `cluster_run/${servicePk}/job_parts/${vectorTypeOfficial}/reports/${
                context.rootState.cluster.clusterResults
                    ? context.rootState.cluster.clusterResults.report_id
                    : ''
            }/export/results`,
        };

        let requestUrl = params.requestUrl ?? urlRequestMap[serviceType];

        let urlResp = await apiGet(requestUrl, errorHandler);
        if (!urlResp.data || !urlResp.data[payloadUrlPropName]) {
            if (errorHandler) {
                return errorHandler(urlResp);
            }
            throw Error(
                `Api did not return url for export with service type ${serviceType} and url ${requestUrl}.`
            );
        }

        const downloadUrl = urlResp.data[payloadUrlPropName];

        if (fileName && !fileName.endsWith('.zip')) {
            fileName += '.zip';
        }

        // get file name if one is not specified
        if (!fileName) {
            fileName = downloadUrl.split(
                'response-content-disposition=attachment%3B%20filename%3D'
            )[1];

            if (fileName) {
                fileName = fileName.split('&')[0];
            }

            if (!fileName) {
                let url = URL.parse(downloadUrl);

                let filePath = url.pathname.split('/');
                fileName = filePath[filePath.length - 1];
            }
        }

        await downloadFileFromUrl(downloadUrl, fileName);

        context.commit('SET_EXPORT_IS_LOADING', false);
        context.commit('SET_SHOW_EXPORT', false);
    },
};

export const store = createStore({
    state: () => ({
        globalErrorMsg: null,
        projectPK: null,
        exportTypesSelected: {
            patent: false,
            dc: false,
            ptab: false,
            itc: false,
            assignee: false,
            litigation_threat: false,
            precompiled_file: false,
        },
        exportIsLoading: false,
        showExport: false,
        showInApp: true,
    }),
    mutations: {
        SET_ERROR_MSG: (state, msg) => {
            state.globalErrorMsg = msg;
        },
        SET_PROJECT_PK: (state, pk) => {
            state.projectPK = pk;
        },
        SET_EXPORT_IS_LOADING: (state, isLoading) => {
            state.exportIsLoading = isLoading;
        },
        SET_EXPORT_SELECTIONS: (state, { exportType, selected }) => {
            state.exportTypesSelected[exportType] = selected;
        },
        SET_SHOW_EXPORT: (state, show) => {
            state.showExport = show;
        },
        RESET_EXPORT_SELECTIONS: state => {
            state.exportTypesSelected = {
                patent: false,
                cpi: false,
                dc: false,
                ptab: false,
                itc: false,
                assignee: false,
                litigation_threat: false,
                precompiled_file: false,
            };
        },
        SET_SHOW_IN_APP: (state, show) => {
            state.showInApp = show;
        },
    },
    actions: actions,
    modules: {
        entity,
        user,
        projects,
        query,
        assignee,
        similarity,
        cluster,
        publication,
        apiConfig,
    },
});
