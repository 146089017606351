'use strict';

import axios from 'axios';
import { appConfig } from '@/common/appConfig';
import { useRouter } from 'vue-router';
import { apiGet, apiPost } from '@/common/apiCommunicator';
import { downloadFileFromUrl } from '@/common/files';
import { getClusterVisualizationData } from '@/services/clusterVisualizationData';
import { logger } from '@moatmetrics/vue-logger';

const API_URL = appConfig.apiUrl;

const getClusterData = async (path, context) => {
    return apiGet(
        path,
        () => {
            context.commit(
                'SET_ERROR_MSG',
                'An error occurred retrieving cluster details. Please contact the support team.'
            );
        },
        () => {
            context.commit(
                'SET_ERROR_MSG',
                'An error occurred retrieving cluster details. Please try again. If the problem persists, contact the support team.'
            );
        }
    );
};
const promoteEstimatedBest = reports => {
    let array = reports;
    array.some(
        (item, idx) =>
            item.estimated_best &&
            array.unshift(
                // remove the found item, in-place (by index with splice),
                // returns an array of a single item removed
                array.splice(idx, 1)[0]
            )
    );
    return array;
};

const sortReportClusters = report => {
    let reportHolder = report.clusters.sort((a, b) => {
        return b.num_patents - a.num_patents;
    });
    reportHolder.push(
        reportHolder.splice(
            reportHolder.findIndex(element => element.cluster_number === -1),
            1
        )[0]
    );

    return reportHolder;
};

const defaultClusteringRunConfigValues = {
    minClusterSize: 2,
    minClusterCount: 2,
    maxClusterSize: null,
    maxClusterCount: 250,
    dropLowerScoredResults: true,
};

const defaultClusteringConfig = {
    min_cluster_size: defaultClusteringRunConfigValues.minClusterSize,
    max_cluster_size: defaultClusteringRunConfigValues.maxClusterSize,
    min_num_clusters: defaultClusteringRunConfigValues.minClusterCount,
    max_num_clusters: defaultClusteringRunConfigValues.maxClusterCount,
    drop_lower_scored_results:
        defaultClusteringRunConfigValues.dropLowerScoredResults,
};

const defaultAdaptiveClusteringConfig = {
    min_cluster_size: defaultClusteringRunConfigValues.minClusterSize,
    min_num_clusters: defaultClusteringRunConfigValues.minClusterCount,
    drop_lower_scored_results:
        defaultClusteringRunConfigValues.dropLowerScoredResults,
    experimental_params: defaultClusteringRunConfigValues.experimental_params,
};

const cluster = {
    namespaced: true,
    state: () => ({
        clusterResults: null,
        lowestDropped: false,
        clusterProcessActive: false,
        isLoading: false,
        simSectionLoading: false,
        clusterMeta: null,
        allVectorData: null,
        CSVisLoading: false,
        clusterBoardLoading: false,
        currentResultSets: null,
        clusterRunPartialSuccess: false,
        clusterRunPk: null,
        errorMsg: null,
        patentDcDownloading: false,
        patentPtabDownloading: false,
        patentItcDownloading: false,
        loadingAllResultSets: false,
        currentVectorPK: null,
        clusterJobRunning: false,
        clusterRunParams: null,
        defaultClusteringConfig: defaultClusteringConfig,
        ...defaultClusteringRunConfigValues,
        defaultAdaptiveClusteringConfig: defaultAdaptiveClusteringConfig,
        clusteringConfigs: null,
        vectorTypes: null,
        defaultVectorType: null,
        currentVectorType: null,
        failedVectors: null,
        successVectors: null,
        colorSelectedResultSets: [],
        currentBoardColorVectorType: null,
        showClusterBoardFileUpload: false,
        projectName: null,
        boardUpdaterFromRoute: 0,
        migrationLoading: false,
        migrationMsg: {},
        clusterPageRouteWatchParams: null,
        patentClusterOptionType: 'portfolio',
        latestJobId: '',
        newClusterResult: false,
        showNewClusterResultNotification: false,
        newClusterInfo: {},
    }),
    mutations: {
        SET_PATENT_CLUSTER_OPTION_TYPE: (state, value) => {
            state.patentClusterOptionType = value;
        },
        ROUTE_WATCH_PARAM_CHANGE: (state, params) => {
            state.clusterPageRouteWatchParams = params;
        },
        SET_CURRENT_CLUSTER_RESULTS: (state, results) => {
            state.clusterResults = results;
        },
        SET_ALL_CLUSTER_REPORTS: (state, results) => {
            state.currentResultSets = results;
        },
        SET_DROP_LOWEST_SCORED: (state, bool) => {
            state.lowestDropped = bool;
        },
        SET_CLUSTER_PROCESS_ACTIVE: (state, bool) => {
            state.clusterProcessActive = bool;
        },
        IS_LOADING: (state, bool) => {
            state.isLoading = bool;
            document.getElementById('app').click();
        },
        SET_CLUSTER_META: (state, meta) => {
            state.clusterMeta = meta;
        },
        SET_ALL_VECTOR_DATA: (state, data) => {
            state.allVectorData = data;
        },
        SET_SIMILARITY_SECTION_LOADING: (state, bool) => {
            state.simSectionLoading = bool;
        },
        CSV_IS_LOADING: (state, bool) => {
            state.CSVisLoading = bool;
        },
        CLUSTER_BOARD_LOADING: (state, bool) => {
            state.clusterBoardLoading = bool;
        },
        CLEAR_CLUSTER_PAGE: state => {
            state.clusterResults = null;
            state.currentResultSets = null;
            state.errorMsg = null;
        },
        CLEAR_CLUSTER_META: state => {
            state.clusterMeta = null;
        },
        CLEAR_CURRENT_VECTOR_TYPE: state => {
            state.currentVectorType = null;
        },
        SET_CLUSTER_RUN_PARTIAL_SUCCESS: (state, data) => {
            state.clusterRunPartialSuccess = data;
        },
        SET_CLUSTER_RUN_PK: (state, id) => {
            state.clusterRunPk = id;
        },
        SET_ERROR_MSG: (state, msg) => {
            state.errorMsg = msg;
        },
        SET_PATENT_DC_DOWNLOADING: (state, bool) => {
            state.patentDcDownloading = bool;
        },
        SET_PATENT_PTAB_DOWNLOADING: (state, bool) => {
            state.patentPtabDownloading = bool;
        },
        SET_PATENT_ITC_DOWNLOADING: (state, bool) => {
            state.patentItcDownloading = bool;
        },
        LOADING_ALL_RESULT_SETS(state, bool) {
            state.loadingAllResultSets = bool;
        },
        CURRENT_VECTOR_PK(state, vectorPK) {
            state.currentVectorPK = vectorPK;
        },
        SET_CLUSTER_JOB_RUNNING: (state, bool) => {
            state.clusterJobRunning = bool;
        },
        SET_CLUSTER_RUN_PARAMS: (state, params) => {
            state.clusterRunParams = params ? { ...params } : null;
        },
        SET_DEFAULT_VECTOR_TYPE: (state, vectorType) => {
            state.defaultVectorType = vectorType;
        },
        SET_CURRENT_VECTOR_TYPE: (state, vectorType) => {
            state.currentVectorType = vectorType;
        },
        SET_CURRENT_BOARD_COLOR_VECTOR_TYPE: (state, vectorType) => {
            state.currentBoardColorVectorType = vectorType;
        },
        SET_CLUSTERING_CONFIG_VALUE: (
            state,
            { vectorType, configKey, val }
        ) => {
            if (!state.clusteringConfigs) {
                state.clusteringConfigs = {};
            }
            state.clusteringConfigs[vectorType][configKey] = val;
        },
        SET_CLUSTERING_CONFIG: (state, { vectorType, config }) => {
            if (!state.clusteringConfigs) {
                state.clusteringConfigs = {};
            }
            state.clusteringConfigs[vectorType] = { ...config };
        },
        SET_VECTOR_TYPES: (state, vectorTypes) => {
            state.vectorTypes = vectorTypes;
        },
        SET_FAILED_VECTORS: (state, clusterRunVectorPks) => {
            state.failedVectors = [...clusterRunVectorPks];
        },
        SET_SUCCESS_VECTORS: (state, vectorObjs) => {
            state.successVectors = [...vectorObjs];
        },
        SET_COLOR_RESULT_SETS(state, resultSets) {
            state.colorSelectedResultSets = resultSets;
        },
        SHOW_CLUSTER_BOARD_FILE_UPLOAD(state, bool) {
            state.showClusterBoardFileUpload = bool;
        },
        SET_PROJECT_NAME(state, name) {
            state.projectName = name;
        },
        UPDATE_BOARD(state) {
            state.boardUpdaterFromRoute += 1;
        },
        SET_MIGRATION_LOADING(state, bool) {
            state.migrationLoading = bool;
        },
        SET_MIGRATION_STATUS_MSG(state, msg) {
            state.migrationMsg = msg;
        },
        RESET_CLUSTER_RUN_STATE: state => {
            state.clusterJobRunning = false;
            state.errorMsg = null;
            state.clusterRunParams = null;
            state.clusterProcessActive = false;
            state.patentClusterOptionType = 'portfolio';
        },
        UPDATE_CLUSTER_LABEL(state, { clusterPk, label }) {
            const index = state.clusterResults.clusters.findIndex(
                val => val.cluster_number === clusterPk
            );
            const targetCurrentResultSet = state.currentResultSets.findIndex(
                val =>
                    val.cluster_report_pk ===
                    state.clusterResults.cluster_report_pk
            );

            state.clusterResults.clusters[index].label = label;
            state.currentResultSets[targetCurrentResultSet].clusters[
                index
            ].label = label;
        },
        UPDATE_JOB_ID: (state, id) => {
            state.latestJobId = id;
        },
        NEW_CLUSTER_RESULT: (state, val) => {
            state.newClusterResult = val;
        },
        SHOW_NEW_CLUSTER_RESULT_NOTIFICATION: (state, val) => {
            state.showNewClusterResultNotification = val;
        },
        NEW_CLUSTER_INFO: (state, val) => {
            state.newClusterInfo = val;
        },
    },
    actions: {
        //For individual cluster runs
        getStatusAndSubmit(context, job) {
            var interval = setInterval(() => {
                axios
                    .get(`${API_URL}/jobs/${job.job_pk}/status`)
                    .then(async result => {
                        if (result.data.status === 'FAILURE') {
                            clearInterval(interval);
                            context.commit(
                                'SET_ERROR_MSG',
                                result.data.message
                            );
                            context.commit('SET_CLUSTER_JOB_RUNNING', false);
                        } else if (result.data.status === 'PARTIAL') {
                            clearInterval(interval);
                            context.commit(
                                'SET_ERROR_MSG',
                                result.data.message
                            );
                            context.commit('SET_CLUSTER_JOB_RUNNING', false);
                            let fullStatusMessage = await context.dispatch(
                                'fetchApiClusterMeta',
                                job.cluster_run_pk
                            );
                            context.commit(
                                'SET_CLUSTER_RUN_PARTIAL_SUCCESS',
                                fullStatusMessage.data.vectors
                            );
                            context.commit('SET_CLUSTER_PROCESS_ACTIVE', false);
                            if (context.state.newClusterResult) {
                                context.commit(
                                    'SHOW_NEW_CLUSTER_RESULT_NOTIFICATION',
                                    true
                                );
                                context.commit('NEW_CLUSTER_INFO', {
                                    jobId: result.data.job_id,
                                    clusterRunPk: job.cluster_run_pk,
                                });
                            } else {
                                context.commit(
                                    'SET_CLUSTER_RUN_PK',
                                    job.cluster_run_pk
                                );
                                context.dispatch(
                                    'handleRouting',
                                    job.cluster_run_pk
                                );
                            }
                        } else if (result.data.status === 'SUCCESS') {
                            clearInterval(interval);
                            if (context.state.newClusterResult) {
                                context.commit(
                                    'SHOW_NEW_CLUSTER_RESULT_NOTIFICATION',
                                    true
                                );
                                context.commit('NEW_CLUSTER_INFO', {
                                    jobId: result.data.job_id,
                                    clusterRunPk: job.cluster_run_pk,
                                });
                            } else {
                                context.commit(
                                    'UPDATE_JOB_ID',
                                    result.data.job_id
                                );
                                context.dispatch(
                                    'handleRouting',
                                    job.cluster_run_pk
                                );

                                if (job.type === 'export') {
                                    context.dispatch('exportCluster');
                                }

                                context.dispatch('resetClusterRunState');
                            }
                            context.commit('SET_CLUSTER_PROCESS_ACTIVE', false);
                            context.commit('SET_CLUSTER_JOB_RUNNING', false);
                        }
                    });
            }, 2000);
        },
        async startMigration(context, clusterPK) {
            try {
                var postMigration = await axios.post(
                    `${API_URL}/cluster_run/${clusterPK}/migrate`
                );
            } catch (ex) {
                logger.error(ex);
                return;
            }
            return postMigration;
        },
        // For cluster migration status
        async getMigrationStatus({ commit }, jobPK) {
            return await new Promise(resolve => {
                var interval = setInterval(async () => {
                    await axios
                        .get(`${API_URL}/jobs/${jobPK}/status`)
                        .then(result => {
                            if (
                                result.data.status === 'SUCCESS' ||
                                result.data.status === 'IMPORTING_PARTIAL' ||
                                result.data.status === 'IMPORTING_FAILURE' ||
                                result.data.status === 'PARTIAL'
                            ) {
                                resolve(result.data.status);
                                clearInterval(interval);
                            } else if (result.data.status === 'IMPORTING') {
                                commit('SET_MIGRATION_LOADING', true);
                            }
                        });
                }, 2000);
            });
        },
        async checkLegacyBoard(context, path) {
            try {
                // CHANGE W/PINIA
                var meta = await axios.get(`${API_URL}${path}`);
            } catch (ex) {
                logger.error(ex);
                return;
            }
            return meta.data;
        },
        async submitClusterJob({ commit, dispatch, state }, payload) {
            commit('SET_CLUSTER_JOB_RUNNING', true);

            if (state.patentClusterOptionType === 'similar') {
                payload.similar_patents = true;
            }

            let errorHandler = () => {
                commit(
                    'SET_ERROR_MSG',
                    'An error occurred while submitting your cluster job. Please try again. If the problem persists, contact the support team.'
                );
                commit('SET_CLUSTER_JOB_RUNNING', false);
            };

            try {
                const clusterKey = await apiPost(
                    `cluster_run/run`,
                    payload,
                    errorHandler
                );
                if (clusterKey) {
                    dispatch('getStatusAndSubmit', clusterKey.data);
                }
            } catch (ex) {
                logger.error(ex);
                errorHandler();
            }
        },
        runClusteringService({ commit }, params) {
            commit('SET_CLUSTER_RUN_PARAMS', params);
            commit('SET_CLUSTER_PROCESS_ACTIVE', true);
        },
        async fetchApiClusterMeta(context, id) {
            try {
                // CHANGE W/PINIA
                const meta = await axios.get(`${API_URL}/cluster_run/${id}`);
                return meta;
            } catch (ex) {
                logger.error(ex);
                return null;
            }
        },
        async getClusterMeta({ commit, dispatch, rootState }, id) {
            commit('CLEAR_CLUSTER_META');
            let meta = await dispatch('fetchApiClusterMeta', id);

            if (!meta) return;

            let metaParams = {
                clusterPK: meta.data.cluster_run_pk,
                projectPK: meta.data.project_pk,
                ...meta.data,
            };
            commit('SET_CLUSTER_META', metaParams);

            for (let item of meta.data.source_sets) {
                switch (item.set_source) {
                    case 'Similarity':
                        await dispatch(
                            'similarity/getSimilarityMeta',
                            {
                                SimRunPK: item.similarity_run_pk,
                                loadQuery: false,
                            },
                            { root: true }
                        );
                        if (!rootState.similarity.similarityResults) {
                            commit('SET_SIMILARITY_SECTION_LOADING', true);
                            await dispatch(
                                'similarity/getSimilarityResults',
                                { simPK: item.similarity_run_pk },
                                { root: true }
                            );
                        }
                        metaParams.similarityPK = item.similarity_run_p;
                        break;

                    case 'Query':
                    case 'QuerySet':
                    case 'PatentSearchSelected':
                        metaParams.queryPK = item.query_pk;
                        if (item.set_type === 'Assignee') {
                            await dispatch(
                                'assignee/getPreviouslySelectedAssignees',
                                item.query_pk,
                                { root: true }
                            );
                        }
                        if (item.set_type === 'Patent') {
                            await dispatch('publication/getSavedSearch', item, {
                                root: true,
                            });
                        }
                        if (item.set_type === 'Entity') {
                            await dispatch(
                                'entity/getPreviouslySelectedEntities',
                                item.query_pk,
                                { root: true }
                            );
                        }
                        break;
                }
            }

            let hasBeenMigrated = meta.data.is_migrated;
            let params = {
                clusterRunPk: meta.data.cluster_run_pk,
            };
            // If the job has NOT been migrated, start the migration
            if (!hasBeenMigrated) {
                await dispatch('startMigration', metaParams.clusterPK);
            }
            // Check the migration status
            let migrationStatus = await dispatch(
                'getMigrationStatus',
                meta.data.job_pk
            );

            if (migrationStatus === 'SUCCESS') {
                commit('SET_MIGRATION_LOADING', false);
                await dispatch('getAllClusterResults', params);
            } else if (migrationStatus === 'IMPORTING_PARTIAL') {
                await dispatch('getAllClusterResults', params);
                await commit('SET_MIGRATION_STATUS_MSG', {
                    title: 'Migration-Partial',
                    msg: 'One or more vector types failed to migrate',
                    type: 'caution',
                });
            } else if (migrationStatus === 'PARTIAL') {
                await dispatch('getAllClusterResults', params);
                await commit('SET_MIGRATION_STATUS_MSG', {
                    title: 'Partial',
                    msg: 'One or more vector types failed to cluster',
                    type: 'caution',
                });
            } else if (migrationStatus === 'IMPORTING_FAILURE') {
                await dispatch('getAllClusterResults', null);
                await commit('SET_MIGRATION_STATUS_MSG', {
                    title: 'Migration-Failed',
                    msg:
                        'Try creating a new search query with the same parameters or contact support for help.',
                    type: 'error',
                });
            }
            commit('SET_MIGRATION_LOADING', false);
        },
        async getAllClusterResults({ commit, dispatch, state }, params) {
            if (!params) {
                commit('SET_ALL_VECTOR_DATA', null);
                commit('LOADING_ALL_RESULT_SETS', false);
                commit('IS_LOADING', false);
                return;
            }

            // Get all vectors, their result sets and the result set clusters and save it to allVectorData
            try {
                // CHANGE W/PINIA
                let currentResultSets = await axios.get(
                    `${API_URL}/cluster_run/${params.clusterRunPk}/job_parts/reports/clusters`
                );

                if (currentResultSets) {
                    commit('SET_ALL_VECTOR_DATA', currentResultSets.data);
                    commit(
                        'SET_DROP_LOWEST_SCORED',
                        currentResultSets.data.job_parts[0].configuration
                            .drop_lower_scored_results
                    );

                    dispatch(
                        'getVectorFailuresAndSuccesses',
                        currentResultSets.data.job_parts
                    );

                    // Select appropriate vector and put best report firsty in array
                    let vectorNameProper =
                        state.clusterMeta.default_vector_name === 'market'
                            ? 'market_shallow_624'
                            : state.clusterMeta.default_vector_name ===
                              'technology'
                            ? 'patent_cpc_512'
                            : 'patent_cpc_512';
                    let defaultVectorName = currentResultSets.data.job_parts.find(
                        vector => vector.vector_type === vectorNameProper
                    );
                    let bestArray = promoteEstimatedBest(
                        defaultVectorName
                            ? defaultVectorName.reports
                            : currentResultSets.data.job_parts[0].reports
                    );
                    commit('SET_ALL_CLUSTER_REPORTS', bestArray);

                    if (state.successVectors.length) {
                        if (!params.clusterRunPk) {
                            if (currentResultSets.data.job_parts[1]) {
                                commit('SET_CURRENT_VECTOR_TYPE', 'technology');
                            } else {
                                commit('SET_CURRENT_VECTOR_TYPE', 'market');
                            }
                        } else {
                            commit(
                                'SET_CURRENT_VECTOR_TYPE',
                                defaultVectorName
                                    ? state.clusterMeta.default_vector_name
                                    : currentResultSets.data.job_parts[0]
                                          .vector_type === 'market_shallow_624'
                                    ? 'market'
                                    : 'technology'
                            );
                        }
                    }

                    // skip current set refresh | for labels
                    if (!params.skipReportRefresh) {
                        defaultVectorName
                            ? defaultVectorName.best_report.clusters
                            : (currentResultSets.data.job_parts[0].best_report.clusters = sortReportClusters(
                                  defaultVectorName
                                      ? defaultVectorName.best_report
                                      : currentResultSets.data.job_parts[0]
                                            .best_report
                              ));
                        commit(
                            'SET_CURRENT_CLUSTER_RESULTS',
                            defaultVectorName
                                ? defaultVectorName.best_report
                                : currentResultSets.data.job_parts[0]
                                      .best_report
                        );
                    }
                }
            } catch (ex) {
                logger.error(ex);
            } finally {
                commit('LOADING_ALL_RESULT_SETS', false);
                commit('IS_LOADING', false);
            }
        },
        switchVectors({ commit, state }, params) {
            commit('IS_LOADING', true);
            let vectorTypeHuman =
                params.type === 'market'
                    ? 'market_shallow_624'
                    : 'patent_cpc_512';
            let vectorTypeIndex = state.allVectorData.job_parts.findIndex(
                item => item.vector_type === vectorTypeHuman
            );

            let bestArray = promoteEstimatedBest(
                state.allVectorData.job_parts[vectorTypeIndex].reports
            );
            commit('SET_ALL_CLUSTER_REPORTS', bestArray);
            commit(
                'SET_CURRENT_CLUSTER_RESULTS',
                state.allVectorData.job_parts[vectorTypeIndex].best_report
            );
            commit('IS_LOADING', false);
        },
        getSingleClusterReport({ state, commit }, params) {
            commit('IS_LOADING', true);
            let newReport = state.currentResultSets.find(
                set => set.report_id === parseInt(params.report_id)
            );
            newReport.clusters = sortReportClusters(newReport);

            commit('SET_CURRENT_CLUSTER_RESULTS', newReport);
            commit('SET_CLUSTER_PROCESS_ACTIVE', false);
            commit('IS_LOADING', false);
        },
        async updateCluster({ commit, state }, params) {
            let vectorType =
                state.currentVectorType === 'technology'
                    ? 'patent_cpc_512'
                    : 'market_shallow_624';
            try {
                const result = await axios.put(
                    `${API_URL}/cluster_run/${state.clusterMeta.clusterPK}/job_parts/${vectorType}/reports/${state.clusterResults.report_id}/clusters/${params.clusterPk}`,
                    { label: params.label }
                );
                if (result.status !== 500) {
                    commit('UPDATE_CLUSTER_LABEL', params);
                    return true;
                } else {
                    throw result;
                }
            } catch (ex) {
                logger.error(ex);
                return false;
            } finally {
                commit('IS_LOADING', false);
            }
        },
        async exportCluster(context) {
            try {
                // CHANGE W/PINIA
                var url = await axios.get(
                    `${API_URL}/cluster_run_report/${context.state.clusterResults[0].cluster_run_report_pk}/export/results`
                );
            } catch (ex) {
                logger.error(ex);
                return;
            }
            context.commit('SET_CLUSTER_PROCESS_ACTIVE', false);
            downloadFileFromUrl(url.data.url, 'cluster_results.zip');
            context.commit('CSV_IS_LOADING', false);
        },
        async loadClusterBoardData(context, { runId, reportPK, color }) {
            context.commit('CLUSTER_BOARD_LOADING', true);
            context.commit('SET_ERROR_MSG', false);

            if (!color) {
                let patents = await context.dispatch(
                    'getClusterPatents',
                    runId
                );
                if (patents) {
                    let clusters = await context.dispatch(
                        'getFinalClusters',
                        reportPK
                    );
                    if (clusters) return;
                }

                context.commit('CLUSTER_BOARD_LOADING', false);
            } else {
                let patents = await context.dispatch(
                    'getClusterPatentsLegend',
                    runId
                );
                if (patents) {
                    let clusters = await context.dispatch(
                        'getFinalClustersLegend',
                        reportPK
                    );
                    if (clusters) return;
                }

                context.commit('CLUSTER_BOARD_LOADING', false);
            }
        },
        async getClusterPatents(context, clusterRunID) {
            let results = await getClusterVisualizationData(clusterRunID);
            if (!results) {
                // FIXME: Retry to load cluster data if the json file doesn't seem to be in place to load from
                // Probably can be removed eventually but in place during the release so we don't have invalid states while
                // the files are being generated for legacy data
                results = await getClusterData(
                    `cluster_run/${clusterRunID}/patents/clustered`,
                    context
                );
                if (!results) return;
                results = results.data;
            }
            // Get user selected vector/current vector
            let currentVectorDataIndex = results.findIndex(
                x => x.vectorPK === parseInt(context.state.currentVectorPK)
            );

            return results;
        },
        async getFinalClusters(context, clusterRunReportID) {
            let results = await getClusterData(
                `cluster_run_report/${clusterRunReportID}/clusters`,
                context
            );
            if (!results) return;

            return results.data;
        },

        async getClusterPatentsLegend(context, clusterRunID) {
            let results = await getClusterVisualizationData(clusterRunID);
            if (!results) {
                // FIXME: Retry to load cluster data if the json file doesn't seem to be in place to load from
                // Probably can be removed eventually but in place during the release so we don't have invalid states while
                // the files are being generated for legacy data
                results = await getClusterData(
                    `cluster_run/${clusterRunID}/patents/clustered`,
                    context
                );
                if (!results) return;
                results = results.data;
            }
            return results;
        },
        async getFinalClustersLegend(context, clusterRunReportID) {
            let results = await getClusterData(
                `cluster_run_report/${clusterRunReportID}/clusters`,
                context
            );
            if (!results) return;

            return results.data;
        },
        export(
            context,
            {
                clusterRunReportPk,
                projectPk,
                userPk,
                fileName,
                selectedOnly,
                resultsCountLimit,
            }
        ) {
            let exportTypesSelected = context.rootState.exportTypesSelected;
            let keys = Object.keys(exportTypesSelected);
            let key;
            let patentExportTypes = [];

            for (key in keys) {
                let exportType = keys[key];
                if (
                    exportTypesSelected[exportType] &&
                    exportType !== 'patent'
                ) {
                    patentExportTypes.push(
                        'PATENT'.concat('_', exportType.toUpperCase())
                    );
                } else if (
                    exportTypesSelected[exportType] &&
                    exportType === 'patent'
                ) {
                    patentExportTypes.push(exportType.toUpperCase());
                }
            }

            context.dispatch(
                'exportData',
                {
                    servicePk: clusterRunReportPk,
                    serviceType: 'CLUSTER',
                    userPk: userPk,
                    projectPk: projectPk,
                    exportTypes: patentExportTypes,
                    fileName: fileName,
                    selectedOnly: selectedOnly,
                    resultsCountLimit: resultsCountLimit,
                    success: () => {},
                    failure: () => {
                        context.commit('SET_EXPORT_IS_LOADING', false, {
                            root: true,
                        });
                        context.dispatch(
                            'errorMsg',
                            'Error exporting data. Contact the support team.',
                            { root: true }
                        );
                    },
                },
                { root: true }
            );
        },
        resetClusterRunState(context) {
            context.commit('SET_CLUSTER_JOB_RUNNING', false);
            context.commit('SET_ERROR_MSG', null);
            context.commit('SET_CLUSTER_RUN_PARTIAL_SUCCESS', null);
            context.commit('SET_CLUSTER_RUN_PK', null);
            context.commit('CLUSTER_BOARD_LOADING', false);
            context.commit('IS_LOADING', true);
        },
        clearState({ commit, dispatch }) {
            commit('CLEAR_CLUSTER_PAGE');
            commit('SET_CLUSTER_JOB_RUNNING', false);
            commit('SET_CLUSTER_PROCESS_ACTIVE', false);
            commit('SET_CLUSTER_RUN_PARAMS', null);
            commit('CLEAR_CLUSTER_META');
            commit('CLEAR_CURRENT_VECTOR_TYPE');
            dispatch('assignee/clearState', null, { root: true });
            dispatch('similarity/clearState', null, { root: true });
            dispatch('publication/clearState', null, { root: true });
        },
        getVectorFailuresAndSuccesses({ commit, state }, jobParts) {
            let failedVectors = [];
            let successVectors = [];
            jobParts.forEach(vectorObj => {
                if (
                    vectorObj.status === 'FAILURE' ||
                    vectorObj.status === 'TIMEOUT'
                ) {
                    failedVectors.push(vectorObj.name);
                }
                if (vectorObj.status === 'SUCCESS') {
                    successVectors.push(vectorObj);
                }
            });
            commit('SET_FAILED_VECTORS', failedVectors);
            commit('SET_SUCCESS_VECTORS', successVectors);

            if (state.failedVectors.length) {
                commit(
                    'SET_ERROR_MSG',
                    'At least one part of the cluster run failed. If the problem persists, contact the support team'
                );
            }
            if (!state.successVectors.length) {
                commit('IS_LOADING', false);
                commit(
                    'SET_ERROR_MSG',
                    'No successful cluster runs to display. If the problem persists, contact the support team'
                );
            }
        },
        async getProject({ commit }, jobID) {
            if (jobID) {
                try {
                    // CHANGE W/PINIA
                    const project = await axios.get(
                        `${API_URL}/projects/${jobID}`
                    );
                    if (project?.data?.name) {
                        commit('SET_PROJECT_NAME', project.data.name);
                    }
                } catch (ex) {
                    logger.error(ex);
                }
            }
        },
        handleRouting({ state, commit }, clusterRunPk) {
            commit('ROUTE_WATCH_PARAM_CHANGE', {
                clusterPk: clusterRunPk,
            });
        },
    },
};

export default cluster;
