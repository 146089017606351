<template>
    <div id="app">
        <AonSideNavigation
            v-if="showNav"
            :user="user"
            title="IP Scape"
            :nav-items="navItems"
            aon-logo-named-route="Dashboard"
            :collapsed="checkRouteForCollapse"
            @clicked="handleDropdownClick"
        />
        <div class="main-content">
            <AonContainer v-if="errorMsg && showInApp" add-page-padding>
                <AonRow>
                    <AonCol class="aon-col-12 mt-5">
                        <AonAlertBox type="error" :title="errorMsg" />
                    </AonCol>
                </AonRow>
            </AonContainer>

            <template v-if="shouldMountRouterView">
                <AonContainer v-if="routeIsDefault" add-page-padding>
                    <AonRow>
                        <AonCol class="aon-col-12 mt-5">
                            <router-view />
                        </AonCol>
                    </AonRow>
                </AonContainer>

                <router-view v-else name="outside" />
            </template>
            <ClusterProcessModal v-if="clusterProcessActive" />
            <AonSpinner
                v-if="showSpinner"
                class="absolute-center"
                :scale="0.5"
                loader-color="tealDark"
            />
            <Toast />
        </div>
    </div>
</template>

<script setup>
import AonSideNavigation from '@moatmetrics/armory/src/components/base/AonSideNavigation.vue';
import AonAlertBox from '@moatmetrics/armory/src/components/base/AonAlertBox.vue';
import AonSpinner from '@moatmetrics/armory/src/components/base/AonSpinner.vue';
import ClusterProcessModal from '@/components/cluster/ClusterProcessModal.vue';
import { useStore } from 'vuex';
import { computed, onMounted, ref, watch, inject } from 'vue';
import { useFlag, useUnleashContext } from '@unleash/proxy-client-vue';

import { useRoute, useRouter, RouterView } from 'vue-router';
import Toast from './components/Toast.vue';
import { useAuth } from '@/auth/authPlugin';
import { appConfig } from '@/common/appConfig';

const router = useRouter();
const route = useRoute();
const store = useStore();
const updateUnleashContext = useUnleashContext();
const authClient = useAuth();

const rumAgent = inject('rumAgent');
const checkRouteForCollapse = ref(false);
const showNav = computed(() => authClient.isAuthenticated.value);
const showSpinner = ref(false);

const enableNewModels = useFlag('ipscape.models');

const routeName = computed(() => route.name);
const routeIsDefault = computed(
    () =>
        router.currentRoute.value.matched.some(o => !!o.components.default) ||
        false
);

const clusterProcessActive = computed(
    () => store.state.cluster.clusterProcessActive
);
const user = computed(() => store.state.user.userData);
const showInApp = computed(() => store.state.showInApp);
const errorMsg = computed(() => store.state.globalErrorMsg);

const shouldMountRouterView = computed(() => {
    return (
        user.value != null ||
        ['Login Callback', 'Login Out'].includes(routeName.value)
    );
});

watch(authClient.isAuthenticated, isAuthenticated => {
    if (isAuthenticated) {
        store.dispatch('apiConfig/getConfig');
    }
});

watch(authClient.user, user => {
    if (user.id) {
        const user = authClient.user.value;

        updateUnleashContext({
            userId: user.email,
        });
        rumAgent.setUser(user.id, user.org_id);

        const params = {
            user_identifier: user.id,
            user_email: user.email,
            name: user.name,
        };

        store.dispatch('user/setUserData', params);
    }
});

watch(routeName, routeName => {
    checkRouteForCollapse.value =
        routeName === 'Cluster Board Path' ||
        routeName === 'Cluster Board Upload';

    showNav.value = !['Log In', 'Log Out'].includes(routeName);
});

const navItems = computed(() => {
    let items = {
        top: [
            {
                label: 'Dashboard',
                routeName: 'Dashboard',
                iconName: 'fa-home',
                iconStyle: 'fas',
                iconColor: 'grey01',
            },
            {
                label: 'Search',
                routeName: 'Search',
                iconName: 'fa-magnifying-glass',
                iconStyle: 'fal',
                iconColor: 'grey01',
            },
            {
                label: 'Dataset Library',
                routeName: 'Dataset Library',
                iconName: 'fa-books',
                iconStyle: 'fass',
                iconColor: 'grey01',
            },
            {
                label: 'Projects',
                routeName: 'Projects',
                iconName: 'fa-folder',
                iconStyle: 'fas',
                iconColor: 'grey01',
            },
            {
                label: 'Cluster Board',
                routeName: 'Cluster Board Upload',
                iconName: 'fa-chart-scatter',
                iconStyle: 'far',
                iconColor: 'grey01',
            },
            {
                label: 'Models',
                routeName: enableNewModels.value ? 'Models' : undefined,
                iconName: 'fa-grid-dividers',
                iconStyle: 'fas',
                iconColor: 'grey01',
                linkOut: enableNewModels.value
                    ? undefined
                    : appConfig.external.patentLabUiUrl,
            },
        ],
        bottom: [
            {
                label: 'Sign Out',
                routeName: 'Log Out',
                iconName: 'fa-arrow-right-from-bracket',
                iconStyle: 'fas',
                iconColor: 'grey01',
            },
        ],
    };

    const entityPortalUrl = appConfig.external.entityPortalUiUrl;
    if (entityPortalUrl) {
        items.top.push({
            label: 'Entity Curation',
            iconName: 'fa-suitcase',
            iconStyle: 'fas',
            iconColor: 'grey01',
            linkOut: entityPortalUrl,
        });
    }

    const tengUrl = appConfig.external.tengUiUrl;
    if (tengUrl) {
        items.top.push({
            label: 'Reports',
            iconName: 'fa-memo',
            iconStyle: 'far',
            iconColor: 'grey01',
            linkOut: tengUrl,
        });
    }

    if (appConfig.incubator.enabled) {
        items.top.push({
            label: 'Incubator',
            iconName: 'fa-flask',
            iconStyle: 'far',
            iconColor: 'grey01',
            routeName: 'Incubator Gallery',
        });
    }
    return items;
});

watch(
    () => store.state.user.userData,
    user => {
        updateUnleashContext({ userId: user.user_email });
    }
);

const handleDropdownClick = params => {
    router.push({ name: params.itemText });
};
</script>

<script>
export default {
    name: 'App',
};
</script>

<style lang="scss">
@import '@moatmetrics/armory/src/sass/main.scss';

#app {
    width: 100%;
    display: flex;
    background: $white;

    .main-content {
        width: 100%;
        overflow: hidden;
    }

    //TODO: Local override for Armory styles. Need to move to armory in the future
    .aon-dropdown {
        $g: &;
        .main-items {
            max-height: unset;

            @at-root {
                ul {
                    #{$g} & {
                        max-height: calc(100vh - 800px);
                        min-height: 45px;
                        overflow-y: auto;
                    }

                    #{$g}.header-actions & {
                        min-height: unset;
                        max-height: unset;
                        height: fit-content;
                    }
                }
            }
        }
    }

    //TODO: Local override for Armory styles. Need to move to armory in the future
    .table-aips-latest {
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
        height: unset;

        .table-filters-container {
            display: flex;
            flex-direction: column;
        }

        .aips-table {
            tbody {
                & > div:last-child {
                    height: 2px;
                }
            }
        }
    }

    //TODO: Local override for Armory styles. Need to move to armory in the future
    .aon-page-with-header {
        padding-bottom: unset !important;
        min-height: 100vh;
    }
}

.absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.aon-side-navigation {
    z-index: 1 !important;
    padding-bottom: 2px;

    .bottom-nav {
        padding-bottom: 2px;
    }
}

// Vue 3 change coming for header links - BB
.a-application-header .aon-dropdown .items ul li {
    &.disabled {
        pointer-events: none;
        opacity: 0.2;
    }
}

.absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.pagination {
    font-family: $font-family;
}

.aon-col {
    max-width: invalid !important;
}

.aon-tooltip-styles {
    // - move to Armory Vue 3 when able - BB
    &.v-popper--theme-dropdown {
        .v-popper__inner {
            background: white !important;
        }

        .popover-row {
            padding-left: 12px;
            padding-right: 12px;

            &:first-of-type {
                padding-top: 12px;
            }

            &:last-of-type {
                padding-bottom: 12px;
            }
        }
        &[data-popper-placement^='top'] .v-popper__arrow-inner {
            border-color: white;
            border-bottom-width: 0;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
        }
        &[data-popper-placement^='top'] .v-popper__arrow-outer {
            border-color: #ddd;
        }
        &[data-popper-placement^='bottom'] .v-popper__arrow-inner {
            border-color: white;
            border-top-width: 0;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-top-color: transparent !important;
        }
        &[data-popper-placement^='bottom'] .v-popper__arrow-outer {
            border-color: #ddd;
        }
        &[data-popper-placement^='right'] .v-popper__arrow-inner {
            border-color: white;
            border-left-width: 0;
            border-left-color: transparent !important;
            border-top-color: transparent !important;
            border-bottom-color: transparent !important;
        }
        &[data-popper-placement^='right'] .v-popper__arrow-outer {
            border-color: #ddd;
        }
        &[data-popper-placement^='left'] .v-popper__arrow-inner {
            border-color: white;
            border-right-width: 0;
            border-top-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
        }
        &[data-popper-placement^='left'] .v-popper__arrow-outer {
            border-color: #ddd;
        }
    }
}
</style>
