import axios from 'axios';
import { logger } from '@moatmetrics/vue-logger';
import { appConfig } from '@/common/appConfig';

const API_URL = appConfig.apiUrl;

const assigneee = {
    namespaced: true,
    state: () => ({
        dirtyState: false,
        searchResults: [],
        selectedResults: [],
        searchLoading: false,
        searchError: null,
        assigneeSectionLoading: false,
        saved: false,
        assigneePatentDataExporting: false,
        assigneeDcDataExporting: false,
        assigneePtabDataExporting: false,
        assigneeItcDataExporting: false,
    }),
    mutations: {
        QUERY_SAVED: (state, bool) => {
            state.saved = bool;
        },
        ADD_ASSIGNEE_TO_SELECTED: (state, result) => {
            state.selectedResults.push(result);
            state.dirtyState = true;
        },
        REMOVE_ASSINGEE_FROM_SEARCH: (state, result) => {
            let removeIndex = state.searchResults
                .map(item => {
                    return item.assignee_name;
                })
                .indexOf(result.assignee_name);

            state.searchResults.splice(removeIndex, 1);
        },
        REMOVE_ASSINGEE_FROM_SELECTED: (state, result) => {
            if (result.assignee_name) {
                let removeIndex = state.selectedResults
                    .map(item => {
                        return item.assignee_name;
                    })
                    .indexOf(result.assignee_name);
                state.selectedResults.splice(removeIndex, 1);
            } else {
                let removeIndex = state.selectedResults
                    .map(item => {
                        return item.id_value;
                    })
                    .indexOf(result.id_value);
                state.selectedResults.splice(removeIndex, 1);
            }
            state.dirtyState = true;
        },
        SET_DIRTY_STATE: (state, isDirty) => {
            state.dirtyState = isDirty;
        },
        SET_SEARCH_RESULTS: (state, results) => {
            if (state.selectedResults && state.selectedResults.length > 0) {
                for (var i = 0; i < state.selectedResults.length; i++) {
                    for (var j = 0; j < results.length; j++) {
                        if (
                            state.selectedResults[i].assignee_name ===
                            results[j].assignee_name
                        ) {
                            results.splice(j, 1);
                        }
                    }
                }
                state.searchResults = results;
            } else {
                state.searchResults = results;
            }
        },
        BULK_SELECTED: (state, results) => {
            state.selectedResults = results;
        },
        CLEAR_SEARCH_RESULTS: state => {
            state.searchResults = [];
            state.searchError = null;
        },
        CLEAR_SELECTED_RESULTS: state => {
            state.selectedResults = [];
        },
        SHOULD_SHOW_LOADER: (state, bool) => {
            state.searchLoading = bool;
        },
        SET_SEARCH_ERROR: (state, error) => {
            state.searchError = error;
        },
        SET_ASSIGNEE_SECTION_LOADING: (state, bool) => {
            state.assigneeSectionLoading = bool;
        },
        SET_ASSIGNEE_PATENT_DATA_EXPORTING: (state, bool) => {
            state.assigneePatentDataExporting = bool;
        },
        SET_ASSIGNEE_DC_DATA_EXPORTING: (state, bool) => {
            state.assigneeDcDataExporting = bool;
        },
        SET_ASSIGNEE_PTAB_DATA_EXPORTING: (state, bool) => {
            state.assigneePtabDataExporting = bool;
        },
        SET_ASSIGNEE_ITC_DATA_EXPORTING: (state, bool) => {
            state.assigneeItcDataExporting = bool;
        },
    },
    actions: {
        assignResult(context, result) {
            context.commit('ADD_ASSIGNEE_TO_SELECTED', result);
            context.commit('REMOVE_ASSINGEE_FROM_SEARCH', result);
        },
        unassignResult(context, result) {
            context.commit('REMOVE_ASSINGEE_FROM_SELECTED', result);
        },
        async companySearch(context, inputString) {
            context.commit('CLEAR_SEARCH_RESULTS');
            context.commit('SHOULD_SHOW_LOADER', true);
            context.commit('SET_SEARCH_ERROR', null);

            try {
                // CHANGE W/PINIA
                var results = await axios.get(
                    `${API_URL}/companies/search?search=${encodeURIComponent(
                        inputString
                    )}`
                );
            } catch (ex) {
                logger.error(ex);
                return;
            }

            // check for empty
            if (results.status !== 200) {
                context.commit('SHOULD_SHOW_LOADER', false);
                context.commit('SET_SEARCH_ERROR', results.data.message);
            } else if (results.data.length <= 0) {
                context.commit('SHOULD_SHOW_LOADER', false);
                context.commit('SET_SEARCH_ERROR', '0 results found');
            } else {
                context.commit('SET_SEARCH_RESULTS', results.data);
                context.commit('SHOULD_SHOW_LOADER', false);
            }
        },
        async getPreviouslySelectedAssignees(context, id) {
            context.commit('SET_ASSIGNEE_SECTION_LOADING', true);
            try {
                // CHANGE W/PINIA
                var results = await axios.get(
                    `${API_URL}/query/${parseInt(id)}/assignees`
                );
            } catch (ex) {
                logger.error(ex);
                return;
            }
            if (results.data) {
                context.commit('BULK_SELECTED', results.data.page_data);
            } else {
                context.commit('BULK_SELECTED', []);
            }

            context.commit('SET_ASSIGNEE_SECTION_LOADING', false);
        },
        clearState(context) {
            context.commit('CLEAR_SELECTED_RESULTS');
            context.commit('CLEAR_SEARCH_RESULTS');
            context.dispatch('publication/clearState', null, { root: true });
        },
        export(
            context,
            {
                setPk,
                projectPk,
                userPk,
                fileName,
                selectedOnly,
                resultsCountLimit,
            }
        ) {
            let exportTypesSelected = context.rootState.exportTypesSelected;
            let keys = Object.keys(exportTypesSelected);
            let key;
            let assigneeExportTypes = [];

            for (key in keys) {
                let exportType = keys[key];

                if (exportTypesSelected[exportType]) {
                    assigneeExportTypes.push(
                        'ASSIGNEE'.concat('_', exportType.toUpperCase())
                    );
                }
            }

            context.dispatch(
                'exportData',
                {
                    servicePk: setPk,
                    serviceType: 'GENERIC',
                    userPk,
                    projectPk,
                    exportTypes: assigneeExportTypes,
                    fileName,
                    selectedOnly,
                    resultsCountLimit,
                    success: () => {},
                    failure: () => {
                        context.commit('SET_EXPORT_IS_LOADING', false, {
                            root: true,
                        });
                        context.commit('SET_SHOW_EXPORT', false, {
                            root: true,
                        });
                        context.dispatch(
                            'errorMsg',
                            'Error exporting data. Contact the support team.',
                            { root: true }
                        );
                    },
                },
                { root: true }
            );
        },
    },
};

export default assigneee;
