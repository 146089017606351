'use strict';

import axios from 'axios';
import { appConfig } from '@/common/appConfig';
import { logger } from '@moatmetrics/vue-logger';

const API_URL = appConfig.apiUrl;

const user = {
    namespaced: true,
    state: () => ({
        userData: null,
    }),
    mutations: {
        SET_USER_DATA: (state, data) => {
            state.userData = { ...data };
        },
    },
    actions: {
        async setUserData(context, params) {
            try {
                var results = await axios.post(`${API_URL}/users`, params);
            } catch (ex) {
                logger.error(ex);
            }
            context.commit('SET_USER_DATA', results.data);
        },
        getUser(context, data) {
            context.commit('SET_USER_DATA', data);
        },
    },
};

export default user;
