<template>
    <div class="toast elevation-5">
        <transition name="toast">
            <AonAlertBox
                v-if="toast"
                v-model="toast"
                :is-contextual="isContextual"
                :type="type"
                :title="title"
                :message="message"
                :link-u-r-l="linkUrl"
                :link-text="linkText"
                can-close
                @close="toast = false"
            />
        </transition>
    </div>
</template>

<script setup>
import { AonAlertBox } from '@moatmetrics/armory/src/components';
import { inject, onMounted, ref } from 'vue';

const toast = ref(false);
const type = ref('notification');
const isContextual = ref(false);
const timeout = ref(10000);
const title = ref('');
const message = ref(null);
const description = ref('');
const alertOptions = ref(null);
const linkUrl = ref(null);
const linkText = ref(null);
const eventBus = inject('eventBus');

onMounted(() => {
    eventBus.on('toast', updateToast);
});

const updateToast = options => {
    type.value = options.type ?? options.color ?? 'notification';
    timeout.value = options.timeout ?? 10000;
    title.value = options.title;
    message.value = options.message;
    description.value = options.description;
    alertOptions.value = options.additional;
    linkUrl.value = options.linkUrl;
    linkText.value = options.linkText;
    isContextual.value = options.isContextual ?? true;

    toast.value = true;

    if (!options.persistent) {
        initTimeout();
    }
};

const initTimeout = () => {
    setTimeout(() => {
        toast.value = false;
    }, timeout.value);
};
</script>

<script>
export default {
    name: 'Toast',
    components: { AonAlertBox },
};
</script>
<style lang="scss" scoped>
.toast {
    position: fixed;
    top: 8rem;
    right: 1rem;
    max-width: 30rem;
    width: 100%;
    z-index: 9000;

    .toast-enter-active,
    .toast-leave-active {
        transition: opacity 250ms, transform 250ms;
    }

    .toast-enter,
    .toast-leave-to {
        opacity: 0;
        transform: translateX(2rem);
    }
}
</style>
