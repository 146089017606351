'use strict';

import axios from 'axios';
import { logger } from '@moatmetrics/vue-logger';
import { appConfig } from '@/common/appConfig';

const API_URL = appConfig.apiUrl;

const apiConfig = {
    namespaced: true,
    state: () => ({
        config: null,
        algorithmDesc: [
            {
                type: 'average',
                desc:
                    'Calculate an average vector from all seed patent/assignee vectors, then find top <span class=italic>n</span> similar patents',
            },
            {
                type: 'individual',
                desc:
                    'For every seed patent/assignee find top <span class=italic>n</span> similar patents, then aggregate results',
            },
        ],
    }),
    mutations: {
        SET_CONFIG: (state, config) => {
            state.config = { ...config };
        },
    },
    actions: {
        async getConfig(context) {
            try {
                // CHANGE W/PINIA
                const results = await axios.get(`${API_URL}/config`);

                if (results.status !== 200) {
                    logger.error(
                        `Get config return non 200 response: ${results.status}`
                    );
                }

                context.commit('SET_CONFIG', results.data);
            } catch (ex) {
                logger.error(ex);
            }
        },
        clearState(context) {
            context.commit('SET_CONFIG', {});
        },
    },
};

export default apiConfig;
