<template>
    <div class="cluster-process-modal">
        <AonModal
            :title="!clusterJobRunning ? 'Clustering Options' : ''"
            message=""
            col-width="4"
            @close="cancelRun()"
        >
            <template v-if="adaptiveClusteringFeatureFlag">
                <AonToggleSwitch
                    v-if="!clusterJobRunning"
                    class="border-break mt-8 mb-4"
                    label="Adaptive Clustering"
                    :label-end="true"
                    unique-i-d="adaptiveToggle"
                    @switch="toggleAdaptive"
                ></AonToggleSwitch>
            </template>
            <template v-if="similarPatentsFeatureFlag">
                <div v-if="showSimilarPatentClusterOptions" class="mb-7">
                    <AonRadioGroup
                        title="Patent Cluster Options"
                        description=""
                        :options="patentClusterOptions"
                        init-selection="portfolio"
                        @clicked="patentClusterOptionSelected"
                    />
                </div>
            </template>
            <ClusterAdaptiveForm
                v-if="showAdaptive && !clusterJobRunning"
                :key="adaptiveUpdater"
            ></ClusterAdaptiveForm>
            <ClusterSimpleForm
                v-if="!showAdaptive && !clusterJobRunning"
            ></ClusterSimpleForm>

            <!-- Errors -->
            <AonAlertBox
                v-if="!clusterJobRunning && errorMsg"
                :type="clusterRunPartialSuccess ? 'caution' : 'error'"
                :title="
                    clusterRunPartialSuccess
                        ? 'Clustering Partially Succeeded'
                        : 'Clustering Failed!'
                "
                :message="
                    clusterRunPartialSuccess ? partialSuccessErrorMsg : errorMsg
                "
            >
                <template #messageData>
                    <div
                        class="content-holder mt-5 pt-5"
                        style="display: flex;"
                    >
                        <AonButton
                            v-if="clusterRunPartialSuccess"
                            class="mr-5"
                            label="Continue Anyway"
                            type="prominent"
                            @clicked="continueToClusterPage()"
                        />
                        <AonButton
                            label="Cancel"
                            type="prominent"
                            @clicked="cancelRun()"
                        />
                    </div>
                </template>
            </AonAlertBox>

            <!-- Spinner -->
            <div v-if="clusterJobRunning" class="spinner-holder mt-n12">
                <AonSpinner
                    :scale="0.5"
                    loader-color="tealDark"
                    style="padding: 150px;"
                />
                <h2 class="peacock--text">Clustering patents...</h2>
                <p class="small">This may take several minutes.</p>
            </div>
        </AonModal>
    </div>
</template>

<script>
export default {
    name: 'ClusterProcessModal',
};
</script>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useFlag } from '@unleash/proxy-client-vue';

import AonSpinner from '@moatmetrics/armory/src/components/base/AonSpinner.vue';
import AonButton from '@moatmetrics/armory/src/components/base/AonButton.vue';
import AonAlertBox from '@moatmetrics/armory/src/components/base/AonAlertBox.vue';
import AonModal from '@moatmetrics/armory/src/components/base/AonModal.vue';
import AonToggleSwitch from '@moatmetrics/armory/src/components/base/AonToggleSwitch.vue';
import AonRadioGroup from '@moatmetrics/armory/src/components/base/forms/AonRadioGroup.vue';

import ClusterSimpleForm from './ClusterSimpleForm.vue';
import ClusterAdaptiveForm from './ClusterAdaptiveForm.vue';

const store = useStore();
const router = useRouter();

//data
const showAdaptive = ref(false);
const adaptiveUpdater = ref(0);
const patentClusterOptions = ref([
    { label: 'Similar Patents', value: 'similar' },
    { label: 'Portfolio Patents', value: 'portfolio' },
]);

//flags
const adaptiveClusteringFeatureFlag = useFlag('ipscape.adaptiveClustering');
const similarPatentsFeatureFlag = useFlag('ipscape.ui.similarpatents');

//computed
const patentClusterOptionType = computed(
    () => store.state.cluster.patentClusterOptionType
);
const similarityRunDetails = computed(
    () => store.state.similarity.similarityRunDetails
);
const hasSimilarPatents = computed(
    () => store.state.similarity.hasSimilarPatents
);

const showSimilarPatentClusterOptions = computed(
    () =>
        hasSimilarPatents.value &&
        !clusterJobRunning.value &&
        similarityRunDetails.value?.similarity_run_config?.algorithm_type ===
            'individual'
);
const clusterJobRunning = computed(() => store.state.cluster.clusterJobRunning);
const clusterRunPartialSuccess = computed(
    () => store.state.cluster.clusterRunPartialSuccess
);
const clusterRunPk = computed(() => store.state.cluster.clusterRunPk);
const errorMsg = computed(() => store.state.cluster.errorMsg);
const partialSuccessErrorMsg = computed(() => {
    if (!clusterRunPartialSuccess.value) return '';

    let wholeMessage = '';
    for (let i = 0; i < clusterRunPartialSuccess.value.length; i++) {
        const element = clusterRunPartialSuccess.value[i];
        if (element.job_part.status === 'FAILURE') {
            // Add spacing between errors
            if (wholeMessage.length > 0) {
                wholeMessage += '\n\n';
            }

            wholeMessage += `${element.name.charAt(0).toUpperCase() +
                element.name.slice(1)} cluster: `;

            for (let j = 0; j < element.job_part.messages.length; j++) {
                const message = element.job_part.messages[j];
                wholeMessage += message.message;
            }
        }
    }
    return wholeMessage;
});

// methods
const patentClusterOptionSelected = patentsType => {
    store.commit('cluster/SET_PATENT_CLUSTER_OPTION_TYPE', patentsType);
};
const continueToClusterPage = () => {
    router.push(`/cluster/${clusterRunPk.value}`);
};
const cancelRun = () => {
    store.dispatch('cluster/resetClusterRunState');
    store.commit('cluster/SET_CLUSTER_PROCESS_ACTIVE', false);
    store.commit('cluster/SET_CLUSTER_RUN_PARAMS', null);
};

const toggleAdaptive = toggleState => {
    showAdaptive.value = toggleState.value;
    adaptiveUpdater.value++;
};
</script>

<style scoped lang="scss">
.cluster-process-modal {
    width: 100%;
    height: 100%;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;

    .spinner-holder {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;

        p,
        h2 {
            width: 100%;
        }
    }

    .border-break {
        border-bottom: 1px solid $navy01;
        padding-bottom: 24px;
    }
}
</style>
