'use strict';
import axios from 'axios';
import { appConfig } from '@/common/appConfig';
import { logger } from '@moatmetrics/vue-logger';
import { searchEntities } from '@/entityApi/entities';

const API_URL = appConfig.apiUrl;

const sortEntities = entities => {
    if (!entities || !entities.length) {
        return [];
    }

    return [...entities].sort(
        (a, b) =>
            b.total_patent_count - a.total_patent_count ||
            a.aon_entity_name.localeCompare(b.aon_entity_name)
    );
};

const entity = {
    namespaced: true,
    state: {
        entitySaved: false,
        dirtyState: false,
        searchError: null,
        searchSaving: false,
        searchResults: [],
        selectedResults: [],
        searchTerm: '',
    },
    mutations: {
        BULK_SELECTED: (state, results) => {
            state.selectedResults = results;
        },
        CLEAR_SEARCH_RESULTS: state => {
            state.searchError = null;
            state.searchResults = [];
            state.searchTerm = null;
        },
        CLEAR_SEARCH_STATE: state => {
            state.searchError = null;
            state.searchResults = [];
            state.searchTerm = null;
            state.selectedResults = [];
            state.searchSaving = false;
            state.entitySaved = false;
        },
        SELECTED_ADD(state, entity) {
            state.searchResults = state.searchResults.filter(
                result => result.aon_entity_pk !== entity.aon_entity_pk
            );

            const selectedResults = [...state.selectedResults];

            selectedResults.push(entity);
            state.selectedResults = sortEntities(selectedResults);
            state.dirtyState = true;
        },
        SELECTED_REMOVE(state, entity) {
            state.selectedResults = state.selectedResults.filter(
                result => result.aon_entity_pk !== entity.aon_entity_pk
            );

            if (
                state.searchTerm &&
                entity.aon_entity_name
                    .toLowerCase()
                    .includes(state.searchTerm.toLowerCase())
            ) {
                const searchResults = [...state.searchResults];

                searchResults.push(entity);
                state.searchResults = sortEntities(searchResults);
            }
            state.dirtyState = true;
        },
        SET_DIRTY_STATE: (state, isDirty) => {
            state.dirtyState = isDirty;
        },
        SET_SEARCH_ERROR: (state, error) => {
            state.searchError = error;
        },
        SET_SEARCH_SAVING: (state, loading) => {
            state.searchSaving = loading;
        },
        SET_SEARCH_RESULTS: (state, results) => {
            if (state.selectedResults && state.selectedResults.length > 0) {
                for (var i = 0; i < state.selectedResults.length; i++) {
                    for (var j = 0; j < results.length; j++) {
                        if (
                            state.selectedResults[i].aon_entity_pk ===
                            results[j].aon_entity_pk
                        ) {
                            results.splice(j, 1);
                        }
                    }
                }
            }

            state.searchResults = results;
        },
        SET_SEARCH_TERM: (state, searchTerm) => {
            state.searchTerm = searchTerm;
        },
    },
    actions: {
        export(
            context,
            {
                setPk,
                projectPk,
                userPk,
                fileName,
                selectedOnly,
                resultsCountLimit,
            }
        ) {
            let exportTypesSelected = context.rootState.exportTypesSelected;
            let keys = Object.keys(exportTypesSelected);
            let key;
            let entityExportTypes = [];

            for (key in keys) {
                let exportType = keys[key];
                if (exportTypesSelected[exportType]) {
                    entityExportTypes.push(
                        'ENTITY'.concat('_', exportType.toUpperCase())
                    );
                }
            }

            context.dispatch(
                'exportData',
                {
                    servicePk: setPk,
                    serviceType: 'GENERIC',
                    userPk: userPk,
                    projectPk: projectPk,
                    exportTypes: entityExportTypes,
                    fileName: fileName,
                    selectedOnly: selectedOnly,
                    resultsCountLimit: resultsCountLimit,
                    success: () => {},
                    failure: () => {
                        context.commit('SET_EXPORT_IS_LOADING', false, {
                            root: true,
                        });
                        context.commit('SET_SHOW_EXPORT', false, {
                            root: true,
                        });
                        context.dispatch(
                            'errorMsg',
                            'Error exporting data. Contact the support team.',
                            { root: true }
                        );
                    },
                },
                { root: true }
            );
        },
        async getPreviouslySelectedEntities({ commit }, id) {
            try {
                const results = await axios.get(
                    `${API_URL}/query/${parseInt(id)}/entities`
                );

                if (results.data) {
                    commit('BULK_SELECTED', results.data.page_data);
                } else {
                    commit('BULK_SELECTED', []);
                }
            } catch (ex) {
                logger.error(ex);

                return;
            } finally {
                commit('SET_DIRTY_STATE', false);
            }
        },
        async search({ commit, state }) {
            if (!state.searchTerm) {
                return;
            }

            commit('SET_SEARCH_ERROR', null);
            commit('SET_SEARCH_SAVING', true);

            try {
                const result = await searchEntities(state.searchTerm.trim(), {
                    page_size: 1000,
                });
                if (![200, 204].includes(result.status)) {
                    commit('SET_SEARCH_ERROR', 'Something went wrong');
                    commit('SET_SEARCH_SAVING', false);

                    logger.error(
                        `${state.searchTerm} - something went wrong with this search request. server status: ${result.status}`
                    );

                    return;
                }

                if (!result.data && !result.data.length) {
                    commit('SET_SEARCH_ERROR', '0 results found');
                    commit('SET_SEARCH_SAVING', false);

                    return;
                }

                const entities = sortEntities(result.data);
                commit('SET_SEARCH_RESULTS', entities);
            } catch (err) {
                commit('SET_SEARCH_ERROR', 'Something went wrong');
                logger.error(err);
            }

            commit('SET_SEARCH_SAVING', false);
        },
    },
};

export default entity;
