import { apiGet } from '@/common/apiCommunicator';

export async function waitForJob(jobPk, success, failure) {
    let interval = setInterval(async () => {
        let statusResp = await apiGet(
            `jobs/${jobPk}/status`,
            () => ({ error: true }),
            () => ({ retry: true })
        );

        if (!statusResp || statusResp.error) {
            clearInterval(interval);
            return failure();
        }

        if (statusResp.retry) return;

        switch (statusResp.data.status) {
            case 'PENDING':
            case 'STARTED':
                return;

            case 'SUCCESS':
                clearInterval(interval);
                return success(statusResp.data);

            default:
                clearInterval(interval);
                return failure(statusResp.data);
        }
    }, 2000);
}
