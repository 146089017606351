'use strict';
import axios from 'axios';
import { appConfig } from '@/common/appConfig';
import { apiGet, apiPost } from '@/common/apiCommunicator';
import { logger } from '@moatmetrics/vue-logger';

const API_URL = appConfig.apiUrl;

const similarity = {
    namespaced: true,
    state: () => ({
        exportCountLimit: 5000,
        filteredResultsCount: 0,
        hasSimilarPatents: false,
        isLoading: false,
        previouslySelectedItems: [],
        previouslySelectedPatents: 0,
        querySet: null,
        querySetID: null,
        resultsLoadErrorMsg: null,
        selectedSimilarityItems: [],
        simDcDownloadRunning: false,
        simDownloadRunning: false,
        similarityAlgorithmType: 'average',
        similarityLoading: false,
        similarityMethodText: '',
        similarityOutputType: 'assignee',
        similarityPageRouteWatchParams: null,
        similarityProcessFailure: false,
        similarityProcessRunning: false,
        similarityRunDetails: {},
        similarityRunPK: null,
        similarityRunType: 'assignee',
        similarityResults: [],
        simItcDownloadRunning: false,
        simMeta: null,
        simPatDcDownloadRunning: false,
        simPatDownloadRunning: false,
        simPatItcDownloadRunning: false,
        simPatPtabDownloadRunning: false,
        simPtabDownloadRunning: false,
        simResultsPageCount: 1,
        showExportCountModal: false,
        totalResultsCount: 0,
        visibleResultsCount: 0,
    }),
    mutations: {
        ROUTE_WATCH_PARAM_CHANGE: (state, params) => {
            state.similarityPageRouteWatchParams = params;
        },
        SET_HAS_SIMILAR_PATENTS: (state, value) => {
            state.hasSimilarPatents = value;
        },
        SET_SIMILARITY_PROCESS_RUNNING: (state, bool) => {
            state.similarityProcessRunning = bool;
        },
        SET_QUERY_SET: (state, set) => {
            state.querySet = set;
        },
        SET_QUERY_SET_ID: (state, id) => {
            state.querySetID = id;
        },
        SET_SIMILARITY_RESULTS: (state, results) => {
            state.similarityResults = results;
        },
        SET_SELECTED_ITEMS: (state, selectedItems) => {
            state.selectedSimilarityItems = [...selectedItems];
        },
        SET_PREV_SELECTED_COUNT: (state, arr) => {
            state.previouslySelectedItems = arr;
        },
        SET_PREV_SELECTED_PATENTS: (state, num) => {
            state.previouslySelectedPatents = num;
        },
        UPDATE_SIM_RESULTS_PAGINATE_PAGE_NUM: (state, page) => {
            state.simResultsPageCount = page;
        },
        SET_ONCE_SIMILARITY_RESULTS: (state, results) => {
            state.similarityResults.push(...results);
            state.visibleResultsCount = state.similarityResults
                ? state.similarityResults.length
                : 0;
        },
        SET_SIMILARITY_RESULTS_FROM_COOKIE: (state, results) => {
            state.similarityResults = results;
        },
        SET_ONCE_PREV_SELECTED_COUNT: (state, arr) => {
            if (arr && state.previouslySelectedItems.length === 0) {
                state.previouslySelectedItems = arr;
            }
        },
        SET_ONCE_PREV_SELECTED_PATENTS: (state, num) => {
            if (num && state.previouslySelectedPatents === 0) {
                state.previouslySelectedPatents = num;
            }
        },
        IS_LOADING: (state, bool) => {
            state.isLoading = bool;
        },
        SET_SIM_META: (state, data) => {
            state.simMeta = data;
        },
        SET_SIM_DOWNLOAD_RUNNING: (state, bool) => {
            state.simDownloadRunning = bool;
        },
        SET_SIM_PAT_DOWNLOAD_RUNNING: (state, bool) => {
            state.simPatDownloadRunning = bool;
        },
        SET_SIM_DC_DOWNLOAD_RUNNING: (state, bool) => {
            state.simDcDownloadRunning = bool;
        },
        SET_SIM_PTAB_DOWNLOAD_RUNNING: (state, bool) => {
            state.simPtabDownloadRunning = bool;
        },
        SET_SIM_ITC_DOWNLOAD_RUNNING: (state, bool) => {
            state.simItcDownloadRunning = bool;
        },
        SET_SIM_PAT_DC_DOWNLOAD_RUNNING: (state, bool) => {
            state.simPatDcDownloadRunning = bool;
        },
        SET_SIM_PAT_PTAB_DOWNLOAD_RUNNING: (state, bool) => {
            state.simPatPtabDownloadRunning = bool;
        },
        SET_SIM_PAT_ITC_DOWNLOAD_RUNNING: (state, bool) => {
            state.simPatItcDownloadRunning = bool;
        },
        SET_SIMILARITY_RUN_TYPE: (state, type) => {
            state.similarityRunType = type;
        },
        SET_SIMILARITY_OUTPUT_TYPE: (state, type) => {
            state.similarityOutputType = type;
        },
        SET_SIMILARITY_ALGORITHM_TYPE: (state, type) => {
            state.similarityAlgorithmType = type;
        },
        SET_TOTAL_RESULTS_COUNT: (state, count) => {
            // don't allow unsetting this value
            if (count) {
                state.totalResultsCount = count;
            }
        },
        SET_FILTERED_RESULTS_COUNT: (state, count) => {
            state.filteredResultsCount = count;
        },
        SET_EXPORT_COUNT_LIMIT: (state, count) => {
            state.exportCountLimit = count;
        },
        SET_SHOW_EXPORT_COUNT_MODAL: (state, bool) => {
            state.showExportCountModal = bool;
        },
        SET_RESULTS_LOAD_ERROR_MSG: (state, msg) => {
            state.resultsLoadErrorMsg = msg;
        },
        SET_SIMILARITY_RUN_DETAILS: (state, simRunDetails) => {
            state.similarityRunDetails = simRunDetails;
        },
        SET_SIMILARITY_METHOD_TEXT: (state, simMethodText) => {
            state.similarityMethodText = simMethodText;
        },
        CLEAR_SELECTED_SIMILARITY_ITEM: state => {
            state.selectedSimilarityItems = [];
        },
        CLEAR_SIMILARITY_RESULTS: state => {
            state.similarityResults = [];
        },
        CLEAR: state => {
            state.similarityProcessRunning = false;
            state.querySet = null;
            state.querySetID = null;
            state.similarityResults = null;
            state.similarityRunPK = null;
            state.previouslySelectedItems = [];
            state.previouslySelectedPatents = 0;
            state.isLoading = false;
            state.simMeta = false;
            state.simResultsPageCount = 1;
            state.totalResultsCount = 0;
            state.exportCountLimit = 5000;
            state.showExportCountModal = false;
            (state.resultsLoadError = null),
                (state.similarityDetails = {}),
                (state.similarityMethodText = '');
        },
        SET_SIMILARITY_PROCESS_FAILURE: (state, bool) => {
            state.similarityProcessFailure = bool;
        },
        SET_SIMILARITY_LOADING: (state, bool) => {
            state.similarityLoading = bool;
        },
    },
    actions: {
        getStatus(context, job) {
            var interval = setInterval(() => {
                axios
                    .get(`${API_URL}/jobs/${job.job_pk}/status`)
                    .then(result => {
                        if (
                            result.data.status === 'FAILURE' ||
                            result.data.status === 'NO_RESULTS'
                        ) {
                            clearInterval(interval);
                            context.commit(
                                'SET_SIMILARITY_PROCESS_RUNNING',
                                false
                            );
                            context.commit('SET_SIM_DOWNLOAD_RUNNING', false);
                            context.commit(
                                'SET_SIM_DC_DOWNLOAD_RUNNING',
                                false
                            );
                            context.commit(
                                'SET_SIM_PTAB_DOWNLOAD_RUNNING',
                                false
                            );
                            context.commit(
                                'SET_SIM_ITC_DOWNLOAD_RUNNING',
                                false
                            );
                            context.commit(
                                'SET_SIM_PAT_DOWNLOAD_RUNNING',
                                false
                            );
                            context.commit(
                                'SET_SIMILARITY_PROCESS_FAILURE',
                                true
                            );
                            if (result.data.status === 'NO_RESULTS') {
                                context.dispatch(
                                    'errorMsg',
                                    'No results found for given filter parameters',
                                    { root: true }
                                );
                            } else {
                                context.dispatch(
                                    'errorMsg',
                                    'Similarity Job failed',
                                    { root: true }
                                );
                            }
                            context.commit(
                                'publication/QUERIES_LOADING',
                                false,
                                { root: true }
                            );
                        } else if (result.data.status === 'SUCCESS') {
                            clearInterval(interval);
                            if (job.type !== 'export') {
                                context.dispatch(
                                    'handleRouting',
                                    job.similarity_run_pk
                                );
                            }

                            context.commit(
                                'SET_SIMILARITY_PROCESS_FAILURE',
                                false
                            );
                        }
                    })
                    .catch(ex => {
                        logger.error(ex);
                    });
            }, 2000);
        },

        async runSimilarity({ commit, dispatch }, params) {
            let errorHandler = () => {
                commit('SET_SIMILARITY_PROCESS_FAILURE', true);
                commit('SET_SIMILARITY_PROCESS_RUNNING', false);
            };

            if (params.output_type) {
                commit(
                    'SET_SIMILARITY_OUTPUT_TYPE',
                    params.output_type.toLowerCase()
                );
            }
            try {
                const similarityResults = await apiPost(
                    `similarity_run/run`,
                    params,
                    errorHandler
                );
                if (similarityResults) {
                    dispatch('getStatus', similarityResults.data);
                }
            } catch (ex) {
                errorHandler();
            }
        },
        async getSimilarityResults(context, params) {
            let finalPageSize = params.pageSize ? params.pageSize : 20;
            context.commit('IS_LOADING', true);
            try {
                var similarityResults = await apiGet(
                    `${API_URL}/similarity_run/${
                        params.simPK
                    }/results?&page_size=${finalPageSize}&page=${
                        params.page ? params.page : 1
                    }&include_seeds=true`
                );
            } catch (ex) {
                logger.error(ex);
                return;
            }
            context.commit(
                'SET_PREV_SELECTED_COUNT',
                similarityResults.data.selected_values
            );
            context.commit(
                'SET_PREV_SELECTED_PATENTS',
                similarityResults.data.selected_patent_count
            );
            context.commit(
                'UPDATE_SIM_RESULTS_PAGINATE_PAGE_NUM',
                Math.ceil(similarityResults.data.total_count / finalPageSize)
            );
            context.commit('SET_SIMILARITY_PROCESS_RUNNING', false);
            context.commit(
                'SET_SIMILARITY_RESULTS',
                similarityResults.data.page_data
            );

            var selectedArry = [];
            if (similarityResults.data.all_selected) {
                similarityResults.data.all_selected.forEach(item => {
                    let patentCount = 0;
                    for (let selected in similarityResults.data
                        .selected_values) {
                        if (selected.assignee_name === item.id_value) {
                            patentCount = selected.patent_count;
                            break;
                        }
                    }
                    selectedArry.push({
                        name: item.id_value,
                        count: patentCount,
                        set_value_pk: item.set_value_pk,
                        selected: item.selected,
                    });
                });
            }
            context.commit('SET_SELECTED_ITEMS', selectedArry);

            context.dispatch('handleRouting', params.simPK);

            context.commit('IS_LOADING', false);
            context.commit('cluster/SET_SIMILARITY_SECTION_LOADING', false, {
                root: true,
            });
        },
        async getSimilarityDetails(context, similarityRunPK) {
            context.commit('IS_LOADING', true);
            try {
                let similarityDetails = await apiGet(
                    `${API_URL}/similarity_run/${similarityRunPK}/details`
                );

                if (similarityDetails.status !== 200) {
                    context.commit('SET_SIMILARITY_RUN_DETAILS', {});
                } else {
                    context.commit(
                        'SET_SIMILARITY_RUN_DETAILS',
                        similarityDetails.data
                    );
                }
            } catch (ex) {
                logger.error(ex);
                context.commit('SET_SIMILARITY_RUN_DETAILS', {});
            }

            context.commit('IS_LOADING', false);
        },
        async getParameterizedSimilarityResults(
            context,
            { queryState, similarityRunPk, userSelected }
        ) {
            let errorHandler = response => {
                logger.error(
                    `Api returned non 2xx response for similarity results request.`
                );
                if (response && response.status) {
                    logger.error(response.status);
                }
                return null;
            };
            let similarityResults;
            const page = queryState.page_size * (queryState.page - 1);
            try {
                context.commit('SET_SIMILARITY_LOADING', true);
                similarityResults = await axios.get(
                    `${API_URL}/similarity_run/${similarityRunPk}/parameterized_results?PL=${page}&PS=${queryState.page_size}`,
                    {
                        params: queryState,
                        'axios-retry': {
                            retries: 10,
                        },
                    }
                );
            } catch (ex) {
                logger.error(ex);
                context.commit('SET_SIMILARITY_LOADING', false);
                return;
            }

            if (similarityResults && similarityResults.data) {
                context.dispatch('commitCounts', similarityResults.data);

                context.commit(
                    'SET_FILTERED_RESULTS_COUNT',
                    similarityResults.data.filtered_total_count
                );

                context.commit('SET_SIMILARITY_PROCESS_RUNNING', false);

                const hasSimilarPatents =
                    similarityResults.data.has_similar_patents === true;

                context.commit('SET_HAS_SIMILAR_PATENTS', hasSimilarPatents);

                if (userSelected && similarityResults.data.page_data) {
                    userSelected.forEach(selectedItem => {
                        similarityResults.data.page_data.forEach(
                            (resultItem, index) => {
                                if (
                                    selectedItem.set_value_pk ===
                                        resultItem.set_value_pk &&
                                    selectedItem.selected
                                ) {
                                    similarityResults.data.page_data[
                                        index
                                    ].selected = true;
                                } else if (
                                    selectedItem.set_value_pk ===
                                    resultItem.set_value_pk
                                ) {
                                    similarityResults.data.page_data[
                                        index
                                    ].selected = false;
                                }
                            }
                        );
                    });
                }

                context.commit(
                    'SET_ONCE_SIMILARITY_RESULTS',
                    similarityResults.data.page_data
                );

                context.commit('SET_SIMILARITY_LOADING', false);

                // Routing...consider moving to method if it get any larger - BB
                context.dispatch('handleRouting', similarityRunPk);
            } else {
                context.commit('SET_FILTERED_RESULTS_COUNT', 0);
                context.commit('SET_SIMILARITY_PROCESS_RUNNING', false);
                context.commit('SET_ONCE_SIMILARITY_RESULTS', []);
            }
            context.commit('cluster/SET_SIMILARITY_SECTION_LOADING', false, {
                root: true,
            });
        },
        async getParameterizedPatentSimilarityResults(
            context,
            { queryState, similarityRunPk, userSelected }
        ) {
            context.commit('IS_LOADING', true);
            context.commit('SET_SIMILARITY_LOADING', true);

            const page = queryState.page_size * (queryState.page - 1);

            // CHANGE W/PINIA
            let similarityResults = await axios.get(
                `${API_URL}/similarity_run/${similarityRunPk}/parameterized_patent_results?PL=${page}&PS=${queryState.page_size}`,
                {
                    params: queryState,
                    'axios-retry': {
                        retries: 10,
                    },
                }
            );

            if (similarityResults && similarityResults.data) {
                context.dispatch('commitCounts', similarityResults.data);

                context.commit(
                    'SET_FILTERED_RESULTS_COUNT',
                    similarityResults.data.filtered_count.count
                );

                if (userSelected && similarityResults.data.page_data) {
                    userSelected.forEach(selectedItem => {
                        similarityResults.data.page_data.forEach(
                            (resultItem, index) => {
                                if (
                                    selectedItem.name ===
                                        resultItem.patent_id &&
                                    selectedItem.selected
                                ) {
                                    similarityResults.data.page_data[
                                        index
                                    ].selected = true;
                                } else if (
                                    selectedItem.name === resultItem.patent_id
                                ) {
                                    similarityResults.data.page_data[
                                        index
                                    ].selected = false;
                                }
                            }
                        );
                    });
                }

                context.commit(
                    'SET_ONCE_SIMILARITY_RESULTS',
                    similarityResults.data.page_data
                );

                // Routing...consider moving to method if it get any larger - BB
                context.dispatch('handleRouting', similarityRunPk);
            } else {
                let errorMsg =
                    'Failed to retrieve similarity results. Please contact the support team.';

                if (
                    context.state.similarityResults &&
                    context.state.similarityResults.length > 0 &&
                    queryState.page - 1 > 0
                ) {
                    // loading more results
                    errorMsg =
                        'Failed to retrieve additional similarity results. Please contact the support team.';
                }

                if (
                    context.state.similarityResults &&
                    context.state.similarityResults.length > 0 &&
                    queryState.page - 1 === 0
                ) {
                    // filter or sort change
                    context.commit('SET_FILTERED_RESULTS_COUNT', 0);
                    context.commit('SET_ONCE_SIMILARITY_RESULTS', []);
                }

                context.commit('SET_RESULTS_LOAD_ERROR_MSG', errorMsg);
            }

            context.commit('SET_SIMILARITY_PROCESS_RUNNING', false);
            context.commit('cluster/SET_SIMILARITY_SECTION_LOADING', false, {
                root: true,
            });
            context.commit('IS_LOADING', false);
            context.commit('SET_SIMILARITY_LOADING', false);
        },
        commitCounts({ commit }, data) {
            commit('SET_ONCE_PREV_SELECTED_COUNT', data.all_selected);
            commit(
                'SET_ONCE_PREV_SELECTED_PATENTS',
                data.selected_patent_count
            );
            commit('SET_TOTAL_RESULTS_COUNT', data.total_count);
        },
        handleRouting({ state, commit }, similarityRunPk) {
            commit('ROUTE_WATCH_PARAM_CHANGE', {
                outputType: state.similarityOutputType,
                simPk: similarityRunPk,
            });
        },
        async getSimilarityMeta(context, { SimRunPK, loadQuery = true }) {
            try {
                // CHANGE W/PINIA
                var meta = await axios.get(
                    `${API_URL}/similarity_run/${SimRunPK}`
                );
            } catch (ex) {
                logger.error(ex);
                return;
            }

            context.commit('SET_SIM_META', meta.data);
            if (meta.data.type) {
                context.commit(
                    'SET_SIMILARITY_RUN_TYPE',
                    meta.data.type.toLowerCase()
                );
            }
            if (meta.data.output_type) {
                context.commit(
                    'SET_SIMILARITY_OUTPUT_TYPE',
                    meta.data.output_type.toLowerCase()
                );
            }

            if (loadQuery) {
                if (meta.data.source_query_pk) {
                    context.dispatch(
                        'query/getQueryDetails',
                        { queryPK: meta.data.source_query_pk },
                        { root: true }
                    );
                } else {
                    context.dispatch(
                        'publication/getSavedSearch',
                        {
                            set_source: 'PatentSearchSelected',
                            set_pk: meta.data.source_set_pk,
                        },
                        { root: true }
                    );
                }
            }
        },
        async updateSet(context, params) {
            try {
                await axios.put(`${API_URL}/set_value/bulk`, params);
            } catch (ex) {
                logger.error(ex);
            }
        },
        async deselectAll(context, params) {
            try {
                await axios.put(
                    `${API_URL}/set/${params.set_pk}/set_values_update_selections`,
                    params
                );
                context.commit('SET_SELECTED_ITEMS', []);
            } catch (ex) {
                logger.error(ex);
            }
        },
        async exportAsPatents(
            context,
            {
                selectedOnly,
                fileName,
                resultsCountLimit,
                patentExportOptionType,
            }
        ) {
            let exportTypesSelected = context.rootState.exportTypesSelected;
            let keys = Object.keys(exportTypesSelected);
            let key;
            let patentExportTypes = [];
            const similarPatents =
                patentExportOptionType === 'similar' ? true : false;

            for (key in keys) {
                let exportType = keys[key];
                if (
                    exportTypesSelected[exportType] &&
                    exportType !== 'patent'
                ) {
                    patentExportTypes.push(
                        'PATENT'.concat('_', exportType.toUpperCase())
                    );
                } else if (
                    exportTypesSelected[exportType] &&
                    exportType === 'patent'
                ) {
                    patentExportTypes.push(exportType.toUpperCase());
                }
            }

            context.dispatch(
                'exportData',
                {
                    servicePk: context.state.simMeta.similarity_run_pk,
                    serviceType: 'SIMILARITY',
                    userPk: context.state.simMeta.user_pk,
                    projectPk: context.state.simMeta.project_pk,
                    exportTypes: patentExportTypes,
                    fileName: fileName,
                    selectedOnly: selectedOnly,
                    resultsCountLimit: resultsCountLimit,
                    similarPatents,
                    success: () => {},
                    failure: () => {
                        context.commit('SET_EXPORT_IS_LOADING', false, {
                            root: true,
                        });
                        context.dispatch(
                            'errorMsg',
                            'Error exporting data. Contact the support team.',
                            { root: true }
                        );
                    },
                },
                { root: true }
            );
        },
        exportAsAssignees(
            context,
            { selectedOnly, fileName, patentExportOptionType }
        ) {
            let exportTypesSelected = context.rootState.exportTypesSelected;
            let keys = Object.keys(exportTypesSelected);
            let key;
            let assigneeExportTypes = [];
            const similarPatents =
                patentExportOptionType === 'similar' ? true : false;

            for (key in keys) {
                let exportType = keys[key];
                if (exportTypesSelected[exportType]) {
                    if (exportType !== 'assignee') {
                        assigneeExportTypes.push(
                            'ASSIGNEE'.concat('_', exportType.toUpperCase())
                        );
                    } else {
                        assigneeExportTypes.push(exportType.toUpperCase());
                    }
                }
            }

            context.dispatch(
                'exportData',
                {
                    servicePk: context.state.simMeta.similarity_run_pk,
                    serviceType: 'SIMILARITY',
                    userPk: context.state.simMeta.user_pk,
                    projectPk: context.state.simMeta.project_pk,
                    exportTypes: assigneeExportTypes,
                    fileName: fileName,
                    selectedOnly: selectedOnly,
                    resultsCountLimit: null,
                    similarPatents,
                    success: () => {},
                    failure: () => {
                        context.commit('SET_EXPORT_IS_LOADING', false, {
                            root: true,
                        });
                        context.dispatch(
                            'errorMsg',
                            'Error exporting data. Contact the support team.',
                            { root: true }
                        );
                    },
                },
                { root: true }
            );
        },
        determineSimilarityMethodText(context) {
            if (context.state.similarityRunDetails) {
                if (context.state.similarityRunDetails.similarity_run_config) {
                    if (
                        context.state.similarityRunDetails.similarity_run_config
                            .algorithm_type === 'individual'
                    ) {
                        if (context.state.similarityRunType === 'assignee') {
                            context.commit(
                                'SET_SIMILARITY_METHOD_TEXT',
                                `The selected similarity approach performs a sampling of patents from the selected assignees, identifies the top <span class=italic>n</span> most similar US patents to each of those, then aggregates the scores to produce a final list of results.`
                            );
                        } else {
                            context.commit(
                                'SET_SIMILARITY_METHOD_TEXT',
                                `The selected similarity approach identifies the top <span class=italic>n</span> most similar US patents for each of the selected patents, then aggregates the scores to produce a final list of results.`
                            );
                        }
                        return;
                    } else {
                        if (context.state.similarityRunType === 'assignee') {
                            context.commit(
                                'SET_SIMILARITY_METHOD_TEXT',
                                `The selected similarity approach performs an aggregation of the patents held by the selected assignees, then compares that to the aggregated portfolios of other assignees to identify the most similar assignees.`
                            );
                        } else {
                            context.commit(
                                'SET_SIMILARITY_METHOD_TEXT',
                                `The selected similarity approach performs an aggregation of the selected patents and then identifies the US patents that appear most similar to that overall collection of patents.`
                            );
                        }
                        return;
                    }
                } else {
                    context.commit('SET_SIMILARITY_METHOD_TEXT', ` `);
                }
            }
            return;
        },
        clearState(context) {
            context.commit('CLEAR_SELECTED_SIMILARITY_ITEM');
            context.commit('CLEAR_SIMILARITY_RESULTS');
            context.commit('CLEAR');
            context.dispatch('assignee/clearState', null, { root: true });
            context.dispatch('publication/clearState', null, { root: true });
        },
        toShowProjectModal(context, show) {
            context.commit('SET_SHOW_EXPORT_COUNT_MODAL', show);
        },
    },
};

export default similarity;
