'use strict';

import axios from 'axios';
import { appConfig } from '@/common/appConfig';
import { logger } from '@moatmetrics/vue-logger';
import { result } from 'lodash-es';

const API_URL = appConfig.apiUrl;

const publication = {
    namespaced: true,
    state: () => ({
        publicationNumbers: null,
        patents: null,
        savedPublicationNumbersCount: null,
        invalidPublicationNumbers: null,
        ambiguousPublicationNumbers: null,
        saved: false,
        loadingQueries: false,
        showInvalidPublicationNumbersModal: false,
        showAmbiguousPublicationNumbersModal: false,
        invalidPublicationsChecklist: null,
        ambiguousPublicationsChecklist: null,
        searchError: null,
        saveWarning: null,
        ambiguousWarning: null,
        dcDataExporting: false,
        patentDataExporting: false,
        ptabDataExporting: false,
        itcDataExporting: false,
        setPk: null,
    }),
    mutations: {
        SET_PUBLICATION_NUMBERS: (state, data) => {
            state.publicationNumbers = data;
        },
        SET_PATENTS: (state, data) => {
            state.patents = [...data];
        },
        SET_SAVED_PUBLICATION_NUMBERS_COUNT: (state, count) => {
            state.savedPublicationNumbersCount = count;
        },
        SET_INVALID_PUBLICATION_NUMBERS: (state, data) => {
            state.invalidPublicationNumbers = data;
        },
        SET_AMBIGUOUS_PUBLICATION_NUMBERS: (state, data) => {
            state.ambiguousPublicationNumbers = data;
        },
        QUERY_SAVED: (state, bool) => {
            state.saved = bool;
        },
        QUERIES_LOADING: (state, bool) => {
            state.loadingQueries = bool;
        },
        SET_SHOW_INVALID_PUBLICATION_NUMBERS_MODAL: (state, bool) => {
            state.showInvalidPublicationNumbersModal = bool;
        },
        SET_SHOW_AMBIGUOUS_PUBLICATION_NUMBERS_MODAL: (state, bool) => {
            state.showAmbiguousPublicationNumbersModal = bool;
        },
        INIT_INVALID_PUBLICATIONS_CHECKLIST: (state, bool) => {
            if (!bool || !state.invalidPublicationNumbers) {
                state.invalidPublicationsChecklist = null;
            } else {
                let pubs = [];
                state.invalidPublicationNumbers.forEach((item, index) => {
                    pubs.push({
                        index,
                        publicationNumber: item,
                        selected: false,
                    });
                });
                state.invalidPublicationsChecklist = pubs;
            }
        },
        INIT_AMBIGUOUS_PUBLICATIONS_CHECKLIST: (state, bool) => {
            if (!bool || !state.ambiguousPublicationNumbers) {
                state.ambiguousPublicationsChecklist = null;
            } else {
                let pubs = [];
                state.ambiguousPublicationNumbers.forEach((item, index) => {
                    pubs.push({
                        index,
                        publicationNumber: item,
                        selected: false,
                    });
                });
                state.ambiguousPublicationsChecklist = pubs;
            }
        },
        SET_SEARCH_ERROR: (state, error) => {
            state.searchError = error;
        },
        SET_SAVE_WARNING: (state, error) => {
            state.saveWarning = error;
        },
        SET_AMBIGUOUS_WARNING: (state, error) => {
            state.ambiguousWarning = error;
        },
        SET_DC_DATA_EXPORTING: (state, bool) => {
            state.dcDataExporting = bool;
        },
        SET_PTAB_DATA_EXPORTING: (state, bool) => {
            state.ptabDataExporting = bool;
        },
        SET_ITC_DATA_EXPORTING: (state, bool) => {
            state.itcDataExporting = bool;
        },
        SET_PATENT_DATA_EXPORTING: (state, bool) => {
            state.patentDataExporting = bool;
        },
        SET_SET_PK: (state, data) => {
            state.setPk = data;
        },
        CLEAR_STATE: state => {
            state.publicationNumbers = null;
            state.patents = null;
            state.savedPublicationNumbersCount = null;
            state.invalidPublicationNumbers = null;
            state.ambiguousPublicationNumbers = null;
            state.saved = null;
            state.loadingQueries = null;
            state.showInvalidPublicationNumbersModal = false;
            state.showAmbiguousPublicationNumbersModal = false;
            state.invalidPublicationsChecklist = null;
            state.searchError = null;
            state.saveWarning = null;
            state.ambiguousWarning = null;
            state.setPk = null;
        },
    },
    getters: {
        patentsFound: state => {
            return state.patents
                ? state.patents.filter(
                      patent =>
                          patent.record_found === undefined ||
                          patent.record_found === true
                  )
                : [];
        },
    },
    actions: {
        clearState(context) {
            context.commit('CLEAR_STATE');
        },
        async getSavedSearch(context, params) {
            if (params.set_source === 'PatentSearchSelected') {
                try {
                    var results = await axios.get(
                        `${API_URL}/patent_set/${params.set_pk}`
                    );
                } catch (ex) {
                    logger.error(ex);
                    return;
                }
            } else {
                try {
                    // CHANGE W/PINIA
                    var results = await axios.get(
                        `${API_URL}/query/${params.query_pk}/patents`
                    );
                } catch (ex) {
                    logger.error(ex);
                    return;
                }
            }

            if (results.status === 200 && results.data) {
                let pubNums = null;
                let resultData = results.data.page_data || results.data;
                resultData.forEach(item => {
                    if (pubNums) {
                        pubNums += ',' + item.id_value ?? item.patent_id;
                    } else {
                        pubNums = item.id_value ?? item.patent_id;
                    }
                });

                context.commit('SET_PUBLICATION_NUMBERS', pubNums);
                context.commit('SET_PATENTS', resultData);
                context.commit(
                    'SET_SAVED_PUBLICATION_NUMBERS_COUNT',
                    resultData.length
                );
                if (resultData.length > 0) {
                    context.commit('SET_SET_PK', resultData[0].set_pk);
                }
            } else if (results.status !== 204) {
                context.commit(
                    'SET_SEARCH_ERROR',
                    'Error occured while retrieving saved query'
                );
            }
            if (!params.shouldRun) {
                context.commit('QUERIES_LOADING', false);
            }
        },
        showInvalidPublicationNumbersModal(context, value) {
            context.commit('SET_SHOW_INVALID_PUBLICATION_NUMBERS_MODAL', value);
            context.commit('INIT_INVALID_PUBLICATIONS_CHECKLIST', value);
        },
        showAmbiguousPublicationNumbersModal(context, value) {
            context.commit(
                'SET_SHOW_AMBIGUOUS_PUBLICATION_NUMBERS_MODAL',
                value
            );
            context.commit('INIT_AMBIGUOUS_PUBLICATIONS_CHECKLIST', value);
        },
        export(context, { setPk, projectPk, userPk, fileName }) {
            let exportTypesSelected = context.rootState.exportTypesSelected;
            let keys = Object.keys(exportTypesSelected);
            let key;
            let patentExportTypes = [];

            for (key in keys) {
                let exportType = keys[key];
                if (
                    exportTypesSelected[exportType] &&
                    exportType !== 'patent'
                ) {
                    patentExportTypes.push(
                        'PATENT'.concat('_', exportType.toUpperCase())
                    );
                } else if (
                    exportTypesSelected[exportType] &&
                    exportType === 'patent'
                ) {
                    patentExportTypes.push(exportType.toUpperCase());
                }
            }

            context.dispatch(
                'exportData',
                {
                    servicePk: setPk,
                    serviceType: 'GENERIC',
                    userPk: userPk,
                    projectPk: projectPk,
                    exportTypes: patentExportTypes,
                    fileName: fileName,
                    selectedOnly: false,
                    resultsCountLimit: null,
                    success: () => {},
                    failure: () => {
                        context.commit('SET_EXPORT_IS_LOADING', false, {
                            root: true,
                        });
                        context.commit('SET_SHOW_EXPORT', false, {
                            root: true,
                        });
                        context.dispatch(
                            'errorMsg',
                            'Error exporting data. Contact the support team.',
                            { root: true }
                        );
                    },
                },
                { root: true }
            );
        },
    },
};

export default publication;
