{
    "action": {
        "added": "Added",
        "assigned": "Assigned",
        "created": "Created",
        "operating_classification_updated": "Updated",
        "reassigned": "Reassigned",
        "removed": "Removed",
        "unassigned": "Unassigned",
        "updated": "Updated",
        "unverified": "Unverified",
        "verified": "User Verified",
        "system_verified": "System Verified"
    },
    "prep": {
        "to": "to",
        "from": "from"
    },
    "type": {
        "entity": "Entity",
        "market": "Market",
        "operatingClassification": "Operating Classification",
        "stockTicker": "Stock Ticker",
        "subsidiary": "Subsidiary",
        "synonym": "Synonym"
    },
    "assigned": "was Assigned to",
    "operating_classification_updated": "Updated operating classification for",
    "history": "History",
    "noHistory": "No history was found",
    "unassigned": "was Unassigned from",
    "unverified": "was Unverified from",
    "verified": "was Verified to"
}
